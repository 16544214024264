import { ArrowDownward, Print } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useReactToPrint } from "react-to-print";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Invoice from "../OrderDetails/Invoice/Invoice";
import PaymentMethod from "../PaymentMethod";
import OTP from "../../../Billing/OTP/OTP";

const OrderDetails = ({}) => {
  const printRef = useRef(null);
  const profileRef = useRef(null);

  const navigate = useNavigate();
  const { orderId } = useParams();
  const [isOTP, setIsOTP] = useState(false);
  const [orderInfo, setOrderInfo] = useState({});
  console.log("orderInfo:", orderInfo);
  const [customOrderCode, setCustomOrderCode] = useState(null);

  const [otpInfo, setOtpInfo] = useState({});
  const [paymentType, setPaymentType] = useState("international");
  console.log("paymentMethodrrr11:", paymentType);
  const [gatewayCharge, setGatewayCharge] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState("");
  console.log("paymentMethodrrr:", paymentMethod);
  const [activeTab, setActiveTab] = useState("");

  useEffect(() => {
    console.log("testQf:", orderId);
    fetch(
      `https://alqamaralzhabi.com/admin_api/booking_api/order_details.php?invoice_number=${orderId}`
    )
      .then((res) => res.json())
      .then((result) => {
        setOrderInfo(result?.[0]);
      });
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const handleGeneratePdf = () => {
    profileRef.current.save();
  };
  const handleChange = (e) => {
    setCustomOrderCode(e.target.value);
  };
  const handleClick = () => {
    navigate("/orderdetails/" + customOrderCode);
    fetch(
      `https://alqamaralzhabi.com/admin_api/booking_api/order_details.php?invoice_number=${customOrderCode}`
    )
      .then((res) => res.json())
      .then((result) => {
        setOrderInfo(result?.[0]);
      });
  };

  const handleGatewayCharge = (charge, paymentInfo) => {
    setGatewayCharge(charge);
    setPaymentMethod(paymentInfo);
  };
  const handleActiveTab = (value) => {
    setActiveTab(value);
  };
  const handleSendOTP = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let orderedItemsc = JSON.parse(orderInfo?.shipment_list?.[0]?.details);
    let grandTotal = Math.ceil(
      (gatewayCharge / 100) *
        parseFloat(orderInfo?.order_details?.[0]?.total_price) +
        parseFloat(orderInfo?.order_details?.[0]?.total_price)
    );

    const formData = new FormData();
    // formData.append("token", "");
    formData.append("order_id", orderId);
    formData.append("phone", orderInfo?.shipment_details?.[0]?.phone);
    formData.append("mail", orderInfo?.shipment_details?.[0]?.email);
    formData.append("user", parsedObject?.SopnoID);
    formData.append("currency", orderedItemsc[0]?.currency);
    formData.append("pay_method", paymentMethod?.name);
    formData.append("total_amount", grandTotal);

    fetch(`https://alqamaralzhabi.com/admin_api/pay_api/token_new.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((result) => {
        if (result?.m_type == "success") {
          setOtpInfo(result);
          setIsOTP(true);
        }
      });
  };
  const afterHit = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    let orderedItemsc = JSON.parse(orderInfo?.shipment_list?.[0]?.details);

    const formData = new FormData();

    let name = orderInfo?.shipment_details?.[0]?.name;
    let phone = orderInfo?.shipment_details?.[0]?.phone;
    let address = orderInfo?.shipment_details?.[0]?.Address;
    let email = orderInfo?.shipment_details?.[0]?.email;
    let sopnoId = parsedObject?.SopnoID;
    let currency = orderedItemsc?.[0]?.currency;
    let grandTotal = Math.ceil(
      (gatewayCharge / 100) *
        parseFloat(orderInfo?.order_details?.[0]?.total_price) +
        parseFloat(orderInfo?.order_details?.[0]?.total_price)
    );

    console.log("grandTotal6666:", grandTotal);

    let myReurnUrl = "https://alqamaralzhabi.com/success/";
    console.log("myReurnUrl:", myReurnUrl);

    // let name = parsedObject?.uname;
    // let phone = parsedObject?.umobile;
    // let address = "dhaka";
    // let email = parsedObject?.uemail;
    // let sopnoId = parsedObject?.sopnoid;

    const form = document.createElement("form");
    form.method = "POST";
    if (paymentMethod?.name == "nagad") {
      form.action = "https://yeapbe.com/ghpay/nagad/index.php";
      // console.log("singleHitted");
      // Sample 1
      const hiddenFieldSample1 = document.createElement("input");
      hiddenFieldSample1.type = "hidden";
      hiddenFieldSample1.name = "cname";
      // hiddenFieldSample1.value = fetchedName?.slice(0, 7);
      hiddenFieldSample1.value = sopnoId?.slice(0, 7);
      form.appendChild(hiddenFieldSample1);

      // Sample 2
      const hiddenFieldSample2 = document.createElement("input");
      hiddenFieldSample2.type = "hidden";
      hiddenFieldSample2.name = "pamount";
      hiddenFieldSample2.value = grandTotal;
      form.appendChild(hiddenFieldSample2);
      // Sample 2

      const hiddenFieldSample3 = document.createElement("input");
      hiddenFieldSample3.type = "hidden";
      hiddenFieldSample3.name = "order_id";
      hiddenFieldSample3.value = orderId;
      form.appendChild(hiddenFieldSample3);

      // Sample 2
      const hiddenFieldSample4 = document.createElement("input");
      hiddenFieldSample4.type = "hidden";
      hiddenFieldSample4.name = "organization";
      hiddenFieldSample4.value = "routeMessage";
      form.appendChild(hiddenFieldSample4);
    }
    if (
      paymentMethod?.name == "mastercard" ||
      paymentMethod?.name == "nexus" ||
      paymentMethod?.name == "dbdebit" ||
      paymentMethod?.name == "visa"

      // paymentType != "cash"
    ) {
      form.action = "https://stripe.ghorami.com/pay";
      console.log("doubleHitted");
      // Sample 1
      const hiddenFieldSample1 = document.createElement("input");
      hiddenFieldSample1.type = "hidden";
      hiddenFieldSample1.name = "name";
      hiddenFieldSample1.value = name;
      form.appendChild(hiddenFieldSample1);

      // Sample 2
      const hiddenFieldSample2 = document.createElement("input");
      hiddenFieldSample2.type = "hidden";
      hiddenFieldSample2.name = "phone";
      hiddenFieldSample2.value = phone;
      form.appendChild(hiddenFieldSample2);
      // Sample 2
      const hiddenFieldSample3 = document.createElement("input");
      hiddenFieldSample3.type = "hidden";
      hiddenFieldSample3.name = "amount";
      hiddenFieldSample3.value = grandTotal;
      form.appendChild(hiddenFieldSample3);
      // Sample 2
      const hiddenFieldSample4 = document.createElement("input");
      hiddenFieldSample4.type = "hidden";
      hiddenFieldSample4.name = "currency";
      hiddenFieldSample4.value = currency;
      form.appendChild(hiddenFieldSample4);
      // Sample 2
      const hiddenFieldSample5 = document.createElement("input");
      hiddenFieldSample5.type = "hidden";
      hiddenFieldSample5.name = "address";
      hiddenFieldSample5.value = address;
      form.appendChild(hiddenFieldSample5);
      // Sample 2
      const hiddenFieldSample6 = document.createElement("input");
      hiddenFieldSample6.type = "hidden";
      hiddenFieldSample6.name = "email";
      hiddenFieldSample6.value = email;
      form.appendChild(hiddenFieldSample6);

      const hiddenFieldSample7 = document.createElement("input");
      hiddenFieldSample7.type = "hidden";
      hiddenFieldSample7.name = "return_url";
      hiddenFieldSample7.value = myReurnUrl;
      form.appendChild(hiddenFieldSample7);

      const hiddenFieldSample8 = document.createElement("input");
      hiddenFieldSample8.type = "hidden";
      hiddenFieldSample8.name = "logo_url";
      hiddenFieldSample8.value =
        "https://alqamaralzhabi.com/admin_api/files/Al-Qamar-Al-Zhabi_logo.png";
      form.appendChild(hiddenFieldSample8);

      const hiddenFieldSample9 = document.createElement("input");
      hiddenFieldSample9.type = "hidden";
      hiddenFieldSample9.name = "brand_text";
      hiddenFieldSample9.value = "alqamaralzhabi.com";
      form.appendChild(hiddenFieldSample9);

      const hiddenFieldSample10 = document.createElement("input");
      hiddenFieldSample10.type = "hidden";
      hiddenFieldSample10.name = "order_id";
      hiddenFieldSample10.value = orderId;
      form.appendChild(hiddenFieldSample10);
    }
    if (paymentMethod?.name == "ghorami") {
      form.action = "https://gopay.ghorami.com/ght/index.php";
      console.log("doubleHitted");
      // Sample 1
      const hiddenFieldSample1 = document.createElement("input");
      hiddenFieldSample1.type = "hidden";
      hiddenFieldSample1.name = "name";
      hiddenFieldSample1.value = name;
      form.appendChild(hiddenFieldSample1);

      // Sample 2
      const hiddenFieldSample2 = document.createElement("input");
      hiddenFieldSample2.type = "hidden";
      hiddenFieldSample2.name = "phone";
      hiddenFieldSample2.value = phone;
      form.appendChild(hiddenFieldSample2);
      // Sample 2
      const hiddenFieldSample3 = document.createElement("input");
      hiddenFieldSample3.type = "hidden";
      hiddenFieldSample3.name = "amount";
      hiddenFieldSample3.value = grandTotal;
      form.appendChild(hiddenFieldSample3);
      // Sample 2
      const hiddenFieldSample4 = document.createElement("input");
      hiddenFieldSample4.type = "hidden";
      hiddenFieldSample4.name = "currency";
      hiddenFieldSample4.value = currency;
      form.appendChild(hiddenFieldSample4);
      // Sample 2
      const hiddenFieldSample5 = document.createElement("input");
      hiddenFieldSample5.type = "hidden";
      hiddenFieldSample5.name = "address";
      hiddenFieldSample5.value = address;
      form.appendChild(hiddenFieldSample5);
      // Sample 2
      const hiddenFieldSample6 = document.createElement("input");
      hiddenFieldSample6.type = "hidden";
      hiddenFieldSample6.name = "email";
      hiddenFieldSample6.value = email;
      form.appendChild(hiddenFieldSample6);

      const hiddenFieldSample7 = document.createElement("input");
      hiddenFieldSample7.type = "hidden";
      hiddenFieldSample7.name = "return_url";
      hiddenFieldSample7.value = myReurnUrl;
      form.appendChild(hiddenFieldSample7);
    }
    if (paymentMethod?.name == "paypal") {
      form.action = "https://paypal.ghorami.com/products/buyProduct";
      console.log("doubleHitted");
      // Sample 1
      const hiddenFieldSample1 = document.createElement("input");
      hiddenFieldSample1.type = "hidden";
      hiddenFieldSample1.name = "cus_name";
      // hiddenFieldSample1.value = "ntest";
      hiddenFieldSample1.value = name;
      form.appendChild(hiddenFieldSample1);

      // Sample 2
      const hiddenFieldSample2 = document.createElement("input");
      hiddenFieldSample2.type = "hidden";
      hiddenFieldSample2.name = "item_name";
      hiddenFieldSample2.value = "alqamar_bill";
      // hiddenFieldSample2.value = phone;
      form.appendChild(hiddenFieldSample2);
      // Sample 2
      const hiddenFieldSample3 = document.createElement("input");
      hiddenFieldSample3.type = "hidden";
      hiddenFieldSample3.name = "amount";
      // hiddenFieldSample3.value = "45";
      hiddenFieldSample3.value = grandTotal;
      form.appendChild(hiddenFieldSample3);
      // Sample 2
      const hiddenFieldSample4 = document.createElement("input");
      hiddenFieldSample4.type = "hidden";
      hiddenFieldSample4.name = "currency";
      hiddenFieldSample4.value = "AED";
      // hiddenFieldSample4.value = currentCurrency;
      form.appendChild(hiddenFieldSample4);
      // Sample 2
      const hiddenFieldSample5 = document.createElement("input");
      hiddenFieldSample5.type = "hidden";
      hiddenFieldSample5.name = "orgranization";
      hiddenFieldSample5.value = "alqamar";
      form.appendChild(hiddenFieldSample5);
      // Sample 2
      const hiddenFieldSample6 = document.createElement("input");
      hiddenFieldSample6.type = "hidden";
      hiddenFieldSample6.name = "cus_email";
      // hiddenFieldSample6.value = "tpu@ghorami.com";
      hiddenFieldSample6.value = email;
      form.appendChild(hiddenFieldSample6);

      const hiddenFieldSample7 = document.createElement("input");
      hiddenFieldSample7.type = "hidden";
      hiddenFieldSample7.name = "order_id";
      hiddenFieldSample7.value = orderId;
      form.appendChild(hiddenFieldSample7);

      const hiddenFieldSample8 = document.createElement("input");
      hiddenFieldSample8.type = "hidden";
      hiddenFieldSample8.name = "return_url";
      hiddenFieldSample8.value = myReurnUrl;
      form.appendChild(hiddenFieldSample8);
    }

    document.body.appendChild(form);
    form.submit();
  };
  return (
    <Box>
      <Container>
        {!isOTP && (
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  paddingY: "60px",
                  paddingX: "30px",
                  height: { xs: "50vh", md: "60vh" },
                }}
              >
                <Stack
                  alignItems="center"
                  justifyContent={"center"}
                  sx={{ height: "100%" }}
                >
                  <Box
                    sx={{
                      height: { xs: "40vh", md: "40vh" },
                    }}
                  >
                    <Stack
                      spacing={0.5}
                      sx={{ marginLeft: { xs: "0%", sm: "21%" } }}
                    >
                      <Typography
                        variant="p"
                        sx={{ fontSize: "12pt", fontWeight: 500 }}
                      >
                        If you have invoice code:
                      </Typography>
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent={"left"}
                      >
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"center"}
                          sx={{
                            borderRadius: "0px",
                            height: "40px",
                            width: "72px",
                            bgcolor: "#686261",
                            borderTopLeftRadius: "6px",
                            borderBottomLeftRadius: "6px",
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: "12pt",
                              fontWeight: 500,
                              color: "white",
                            }}
                          >
                            CODE
                          </Typography>
                        </Stack>

                        <OutlinedInput
                          placeholder="Please enter text"
                          value={customOrderCode}
                          name="order"
                          type="number"
                          size="small"
                          onChange={handleChange}
                          sx={{
                            width: "100%",
                            borderRadius: "0px",
                            borderTopRightRadius: "6px",
                            borderBottomRightRadius: "6px",
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Stack
                      direction={"row"}
                      justifyContent={"right"}
                      alignItems="center"
                      sx={{ marginLeft: { xs: "0%", sm: "21%" } }}
                    >
                      <Button
                        variant="contained"
                        size="medium"
                        sx={{
                          bgcolor: "#686261",
                          borderRadius: "6px",
                          width: "120px",
                          height: { xs: "auto", md: "25px" },
                          marginLeft: "188px",
                          marginTop: "10px",
                          textTransform: "capitalize",
                        }}
                        onClick={handleClick}
                      >
                        Get Invoice
                      </Button>
                    </Stack>
                    <PaymentMethod
                      handleGatewayCharge={handleGatewayCharge}
                      handleActiveTab={handleActiveTab}
                      activeTab={activeTab}
                      setPaymentType={setPaymentType}
                      paymentType={paymentType}
                    />{" "}
                    <Stack
                      direction={"row"}
                      justifyContent={"right"}
                      alignItems="center"
                    >
                      {activeTab ? (
                        <Button
                          variant="contained"
                          size="small"
                          // color="success"
                          sx={{
                            bgcolor: "#2e677d",
                            borderRadius: "6px",
                            marginTop: "10px",
                            textTransform: "capitalize",
                          }}
                          onClick={() => {
                            // afterHit();
                            // setIsOTP(true);
                            handleSendOTP();
                          }}
                        >
                          Pay now
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          disabled
                          sx={{
                            // bgcolor: "#686261",
                            borderRadius: "6px",
                            marginTop: "10px",
                            textTransform: "capitalize",
                          }}
                        >
                          Pay now
                        </Button>
                      )}
                    </Stack>
                  </Box>
                </Stack>
              </Box>
            </Grid>
            {orderInfo?.pr_poster ? (
              <Grid item xs={12} md={6}>
                <Stack
                  spacing={1}
                  direction={"row"}
                  justifyContent={{ xs: "left", md: "right" }}
                  alignItems={"center"}
                  sx={{ height: "70px" }}
                >
                  <Button
                    startIcon={<ArrowDownward />}
                    size="small"
                    // onClick={toPdf}
                    sx={{
                      paddingX: "10px",
                      color: "black",
                      bgcolor: "#e3dfdf",
                      fontSize: "9pt",
                      textTransform: "capitalize",
                      borderRadius: "30px",
                    }}
                    onClick={handleGeneratePdf}
                  >
                    Download
                  </Button>
                </Stack>
                <PDFExport
                  paperSize="A4"
                  // margin="0.5cm"
                  orientation="0"
                  // scale={0.8}
                  fileName={"alqamaralzhabi_receipt-" + orderId}
                  ref={profileRef}
                >
                  <Box
                    sx={{ marginTop: "-30px", marginBottom: "0px" }}
                    ref={printRef}
                  >
                    {orderInfo.order_id && (
                      <Invoice
                        orderInfo={orderInfo}
                        gatewayCharge={gatewayCharge}
                        paymentMethod={paymentMethod}
                      />
                    )}
                  </Box>
                </PDFExport>
              </Grid>
            ) : (
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"center"}
              >
                <Typography
                  variant="p"
                  sx={{ fontSize: "14pt", fontWeight: 600 }}
                >
                  No Invoice found
                </Typography>
              </Stack>
            )}
          </Grid>
        )}
        {isOTP && (
          <OTP
            afterHit={afterHit}
            otpInfo={otpInfo}
            handleSendOTP={handleSendOTP}
          />
        )}
      </Container>
    </Box>
  );
};

export default OrderDetails;
