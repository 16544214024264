import { Box } from "@mui/system";
import React from "react";
import Booknow from "../components/Booknow Component/Booknow";

const BooknowPage = () => {
  return (
    <Box mb={10}>
      <Booknow />
    </Box>
  );
};

export default BooknowPage;
