import { Language, LocationOn, Phone } from "@mui/icons-material";
import { Grid, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";
import QRCode from "react-qr-code";

const Invoice = (props) => {
  const { orderInfo, gatewayCharge, paymentMethod } = props;
  console.log("gatewayChargeInvoice:", gatewayCharge);
  console.log("paisos re vai?", orderInfo.order_id);
  let orderedItemsc = JSON.parse(orderInfo?.shipment_list[0]?.details);
  console.log("orderedItems:", orderedItemsc);

  const initialValue = 0;
  let subTotal = orderedItemsc.reduce(
    (accumulator, element) => accumulator + element.itemTotal,
    initialValue
  );
  return (
    <Paper
      // elevation={0}
      sx={{
        paddingTop: "40px",

        marginTop: "30px",
        // marginBottom: "30px",
        position: "relative",
      }}
    >
      <Box sx={{ paddingX: { xs: "10px", md: "30px" } }}>
        <Box sx={{}}>
          <Grid container spacing={2}>
            <Grid item xs={7.5}>
              <Stack
                alignItems="flex-start"
                justifyContent={"left"}
                sx={{ height: "90px" }}
              >
                <Stack
                  spacing={0.5}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems="center"
                  sx={{ height: "100%" }}
                >
                  <Box
                    component="img"
                    sx={{
                      width: 40,
                    }}
                    src={orderInfo?.shop_info[0]?.shop_logo}
                  />
                  <Typography
                    variant="p"
                    sx={{ fontSize: "13pt", fontWeight: 500 }}
                  >
                    {orderInfo?.shop_info[0]?.shop_name}
                  </Typography>
                </Stack>
                <Stack
                  spacing={0.5}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems="center"
                  sx={{ height: "100%", marginLeft: "22px" }}
                >
                  <LocationOn sx={{ fontSize: "14pt" }} />
                  <Typography
                    variant="p"
                    sx={{ fontSize: "9pt", maxWidth: "200px" }}
                  >
                    {orderInfo?.shop_info[0]?.shop_address}
                  </Typography>
                </Stack>
                <Stack
                  spacing={0.5}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems="center"
                  sx={{ height: "100%", marginLeft: "24px" }}
                >
                  <Phone sx={{ fontSize: "11pt" }} />
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", maxWidth: "200px" }}
                  >
                    {orderInfo?.shop_info[0]?.shop_phone}
                  </Typography>
                </Stack>
                <Stack
                  spacing={0.5}
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems="center"
                  sx={{
                    position: "absolute",
                    left: { xs: "50%", md: "35%" },
                    top: { xs: "170px", md: "150px" },
                    border: "2px solid green",
                    borderRadius: "6px",
                    paddingX: "10px",
                    paddingY: "2px",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", maxWidth: "200px" }}
                  >
                    {orderInfo?.shop_info[0]?.shop_license}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={4.5}>
              <Box>
                <Stack>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: { xs: "16pt", md: "33pt" },
                      fontWeight: 700,
                      marginTop: { xs: "0px", md: "-12px" },
                    }}
                  >
                    INVOICE
                  </Typography>
                  <Stack
                    direction={{ xs: "column", md: "row" }}
                    alignItems={{ xs: "flex-start", md: "center" }}
                    justifyContent={"left"}
                  >
                    <Box sx={{ width: { xs: "60%", md: "35%" } }}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "10pt", fontWeight: 500 }}
                      >
                        INVOICE :
                      </Typography>
                    </Box>
                    <Box sx={{ width: "65%" }}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "10pt", fontWeight: 500 }}
                      >
                        {orderInfo?.order_id}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems="flex-start"
                    justifyContent={"left"}
                  >
                    <Box sx={{ width: "35%" }}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "10pt", fontWeight: 500 }}
                      >
                        DATE
                      </Typography>
                    </Box>
                    <Box sx={{ width: "65%" }}>
                      <Typography
                        variant="p"
                        sx={{ fontSize: "10pt", fontWeight: 500 }}
                      >
                        : {orderInfo?.shipment_details[0]?.created_at}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Stack sx={{ marginTop: "20px" }}>
          <Typography variant="p" sx={{ fontSize: "10pt", fontWeight: 600 }}>
            Customer Info:
          </Typography>
          <Box
            sx={{
              width: "150px",
              height: "4px",
              bgcolor: "#efc11b",
              borderRadius: "10px",
            }}
          ></Box>

          <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: 600 }}>
            {orderInfo?.shipment_details[0]?.name}
          </Typography>
          <Typography variant="p" sx={{ fontSize: "9pt", maxWidth: "200px" }}>
            {orderInfo?.shipment_details[0]?.phone}
          </Typography>
          <Typography variant="p" sx={{ fontSize: "9pt", maxWidth: "200px" }}>
            {orderInfo?.shipment_details[0]?.Address}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-around"}
          sx={{
            bgcolor: "#090940",
            borderRadius: "12px",
            paddingY: "2px",
            paddingX: "5px",
            marginTop: "25px",
          }}
        >
          <Typography
            variant="p"
            sx={{
              color: "white",
              width: "8%",
              fontSize: { xs: "10pt", md: "12pt" },
            }}
          >
            SL
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "white",
              width: "29%",
              fontSize: { xs: "10pt", md: "12pt" },
            }}
          >
            ITEM DESCRIPTION
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "white",
              width: "8%",
              fontSize: { xs: "10pt", md: "12pt" },
            }}
          >
            QTY
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "white",
              width: "15%",
              fontSize: { xs: "10pt", md: "12pt" },
            }}
          >
            RATE
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "white",
              width: "15%",
              fontSize: { xs: "10pt", md: "12pt" },
            }}
          >
            AMOUNT
          </Typography>
        </Stack>
        <Box sx={{ paddingTop: "15px" }}>
          {orderedItemsc.map((element, index) => (
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-around"}
              sx={{
                bgcolor: "#d6d8d9",
                borderRadius: "12px",
                paddingY: "2px",
                paddingX: "5px",
                marginTop: "20px",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  width: "5%",
                  fontWeight: 600,
                  fontSize: { xs: "9pt", md: "12pt" },
                }}
              >
                {index + 1}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  width: "40%",
                  fontWeight: 500,
                  fontSize: { xs: "9pt", md: "12pt" },
                }}
              >
                {element.title}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  width: "5%",
                  fontWeight: 600,
                  fontSize: { xs: "9pt", md: "12pt" },
                }}
              >
                {element.quantity}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  width: "15%",
                  fontWeight: 600,
                  fontSize: { xs: "9pt", md: "12pt" },
                }}
              >
                {element.currency} {element.price}
              </Typography>
              <Typography
                variant="p"
                sx={{
                  width: "15%",
                  fontWeight: 600,
                  fontSize: { xs: "9pt", md: "12pt" },
                }}
              >
                {element.currency}{" "}
                {parseFloat(element.price) * element.quantity}
              </Typography>
            </Stack>
          ))}
        </Box>
        <Box
          sx={{
            marginTop: "30px",
          }}
        >
          <Stack alignItems={"flex-end"}>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ paddingX: "29px", width: "230px" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700 }}
              >
                Subtotal
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 600 }}>
                {orderedItemsc[0]?.currency}{" "}
                {orderInfo?.order_details[0]?.total_amount}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ paddingX: "29px", width: "230px" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700 }}
              >
                Tax or vat(5%)
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 600 }}>
                {/* BDT {orderInfo?.order_details[0]?.vat_amount} */}
                {orderedItemsc[0]?.currency}{" "}
                {orderInfo?.order_details[0]?.vat_amount}
              </Typography>
            </Stack>

            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{ paddingX: "29px", width: "230px" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700 }}
              >
                Gateway Charge
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 600 }}>
                {orderedItemsc[0]?.currency}{" "}
                {gatewayCharge
                  ? Math.ceil(
                      (gatewayCharge / 100) *
                        orderInfo?.order_details[0]?.total_price
                    )
                  : orderInfo?.order_details[0]?.gateway_charge}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                paddingX: "29px",
                width: "150px",
                bgcolor: "#090940",
                borderRadius: "12px",
                marginTop: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700, color: "white" }}
              >
                Total
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 600, color: "white" }}>
                {orderedItemsc[0]?.currency}{" "}
                {gatewayCharge
                  ? Math.ceil(
                      (gatewayCharge / 100) *
                        parseFloat(orderInfo?.order_details[0]?.total_price) +
                        parseFloat(orderInfo?.order_details[0]?.total_price)
                    )
                  : Math.ceil(
                      parseFloat(orderInfo?.order_details[0]?.total_price)
                    )}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                paddingX: "29px",
                width: "150px",
                bgcolor: "#090940",
                borderRadius: "12px",
                marginTop: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700, color: "white" }}
              >
                Paid
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 600, color: "white" }}>
                {orderedItemsc[0]?.currency}{" "}
                {orderInfo?.payment_info[0]?.paid_amount}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              sx={{
                paddingX: "29px",
                width: "150px",
                bgcolor: "#090940",
                borderRadius: "12px",
                marginTop: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "13pt", fontWeight: 700, color: "white" }}
              >
                Due
              </Typography>
              <Typography variant="p" sx={{ fontWeight: 600, color: "white" }}>
                {orderedItemsc[0]?.currency}{" "}
                {orderInfo?.payment_info[0]?.due_amount}
              </Typography>
            </Stack>
          </Stack>
        </Box>
        <Stack
          sx={{ width: "100%", marginTop: "70px" }}
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Stack
            sx={{ width: "40%", borderTop: "2px solid gray" }}
            direction="row"
            alignItems={"center"}
            justifyContent="center"
          >
            <Typography
              variant="p"
              sx={{ fontSize: "9pt", fontWeight: 500, textAlign: "center" }}
            >
              Receiver's signature
            </Typography>
          </Stack>
          <Stack
            sx={{ width: "40%", borderTop: "2px solid gray" }}
            direction="row"
            alignItems={"center"}
            justifyContent="center"
          >
            <Typography
              variant="p"
              sx={{ fontSize: "9pt", fontWeight: 500, textAlign: "center" }}
            >
              Signature
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          sx={{ marginTop: "40px", width: "100%" }}
        >
          <Stack
            direction={"row"}
            alignItems="flex-start"
            justifyContent={"left"}
            spacing={3}
            sx={{ width: "70%" }}
          >
            <Stack sx={{}}>
              <Typography
                variant="p"
                sx={{ fontSize: "10pt", fontWeight: 600 }}
              >
                Payment Method:
              </Typography>
              <Box
                sx={{
                  width: "118px",
                  height: "4px",
                  bgcolor: "#efc11b",
                  borderRadius: "10px",
                }}
              ></Box>
              <Typography variant="p" sx={{ fontSize: "8pt" }}>
                {paymentMethod?.name}
              </Typography>
            </Stack>
            <Stack sx={{}}>
              <Typography
                variant="p"
                sx={{ fontSize: "10pt", fontWeight: 600 }}
              >
                Terms and Conditions:
              </Typography>
              <Box
                sx={{
                  width: "163px",
                  height: "4px",
                  bgcolor: "#efc11b",
                  borderRadius: "10px",
                }}
              ></Box>
              <Typography variant="p" sx={{ fontSize: "8pt" }}>
                No,Exchange- No Return/ only service warranty
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              height: "auto",
              marginRight: { xs: "0px", md: "20px" },
              maxWidth: { xs: 50, md: 74 },
              marginTop: "-15px",
              width: "100%",
            }}
          >
            <QRCode
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={
                "https://alqamaralzhabi.com/orderdetails/" + orderInfo?.order_id
              }
              viewBox={`0 0 256 256`}
            />
          </Box>
        </Stack>
      </Box>

      <Box>
        <Stack
          direction={"row"}
          spacing={0.1}
          justifyContent={"left"}
          alignItems="left"
          marginBottom={"-15px"}
          marginTop={"10px"}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: "8pt",
              fontStyle: "Italic",
              fontWeight: 200,
              paddingX: "30px",
            }}
          >
            "This is a software generated invoice."
          </Typography>
        </Stack>
        <Box
          sx={{
            height: "40px",
            marginLeft: "25%",
            marginRight: "25%",
            paddingX: "10px",

            borderRadius: "20px",
            bgcolor: "white",
            position: "relative",
            bottom: "-20px",
            zIndex: 9,
          }}
        >
          <Stack
            direction={"row"}
            spacing={0.3}
            justifyContent={"center"}
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Language sx={{ fontSize: "10pt" }} />
            <Typography variant="p" sx={{ fontSize: "10pt", fontWeight: 500 }}>
              {orderInfo?.shop_info[0]?.shop_web}
            </Typography>
          </Stack>
        </Box>
        <Box
          sx={{
            height: "30px",
            bgcolor: "#090940",
            marginBottom: "-40px",
          }}
        ></Box>
        <Box
          sx={{
            height: "30px",
            marginLeft: "24%",
            marginRight: "24%",
            borderRadius: "50px",
            bgcolor: "#090940",
            position: "relative",
            bottom: "-10px",
          }}
        ></Box>
        <Box sx={{ height: "40px", bgcolor: "orange" }}></Box>
      </Box>
    </Paper>
  );
};

export default Invoice;
