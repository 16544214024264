import { CheckCircleOutline, Error, Reply, Share } from "@mui/icons-material";
import { Button, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useLocation, useParams } from "react-router-dom";

const PaymentSuccess = () => {
  const location = useLocation();
  console.log("location:", location);
  return (
    <Container>
      <Stack
        mb={6}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ height: "78vh" }}
      >
        <Stack
          spacing={1}
          justifyContent="center"
          alignItems="center"
          sx={{ width: "80%" }}
        >
          {location?.search?.length > 11 ? (
            <CheckCircleOutline
              color="success"
              style={{ fontSize: "85pt", fontWeight: 400 }}
            />
          ) : (
            <Error
              color="error"
              style={{ fontSize: "85pt", fontWeight: 400 }}
            />
          )}
          {location?.search?.length > 11 ? (
            <Typography sx={{ fontSize: "30pt", fontWeight: 400 }}>
              Transaction Completed Successfully
            </Typography>
          ) : (
            <Typography sx={{ fontSize: "30pt", fontWeight: 400 }}>
              Transaction is not completed
            </Typography>
          )}
          <Typography sx={{ fontSize: "18pt", fontWeight: 400 }}>
            Thank you for your billing.
          </Typography>

          <Button variant="contained" color="success" startIcon={<Reply />}>
            We just mail you your order
          </Button>
          <Typography sx={{ fontSize: "12pt", fontWeight: 500 }}>
            info@alqamaralzhabi.com
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

export default PaymentSuccess;
