import { HighlightOff, Person } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import UpdateConfirmModal from "./UpdateConfirmModal";

const UpdateCustomerInfo = ({ customerInfo, setCustomerInfo }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUpdate = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    customerInfo.p_user = parsedObject?.SopnoID;
    // let others = {
    //   category: customerInfo.category,
    //   name: customerInfo.name,
    //   date: customerInfo.date,
    //   Price: customerInfo.Price,
    //   regularPrice: customerInfo.amount,
    //   item_desc: customerInfo.item_desc,
    //   currency: customerInfo.currency,
    //   p_user: parsedObject?.SopnoID,
    // };
    const formData = new FormData();
    formData.append("updatedCustomerInfo", JSON.stringify(customerInfo));
    fetch(
      `https://alqamaralzhabi.com/admin_api/booking_api/Client_info_update.php`,
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        if (result?.m_type) {
          swal({
            title: result?.m_result,
            text: result?.q_message,
            icon: result?.m_type,
            button: "Done",
          });
          handleClose();
          navigate("/upload");
        }
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  };

  const clear = () => {
    setCustomerInfo({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "phone") {
      isFieldValid = /[0-9]+/.test(event.target.value);
    }
    if (event.target.name === "email") {
      isFieldValid = true;
    }
    if (event.target.name === "name") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }
    if (event.target.name === "created_at") {
      isFieldValid = true;
    }

    if (isFieldValid) {
      const newcustomerInfo = customerInfo;
      newcustomerInfo[event.target.name] = event.target.value;
      newcustomerInfo.fieldErrorAlert = "";
      setCustomerInfo({ ...newcustomerInfo });
    }
    if (!isFieldValid) {
      const newcustomerInfo = customerInfo;
      newcustomerInfo[event.target.name] = "";
      newcustomerInfo.fieldErrorAlert = event.target.name;
      setCustomerInfo({ ...newcustomerInfo });
    }
  };
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "12pt",
                  fontWeight: 600,
                }}
              >
                Date
              </Typography>
              {customerInfo?.fieldErrorAlert === "date" && (
                <small style={{ color: "#CB0101" }}>*Enter date</small>
              )}
            </Stack>

            <OutlinedInput
              type="date"
              name="created_at"
              size="small"
              value={customerInfo?.created_at}
              placeholder="Enter date"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "12pt",
                  fontWeight: 600,
                }}
              >
                Name
              </Typography>
              {customerInfo?.fieldErrorAlert == "name" && (
                <small style={{ color: "#CB0101" }}>
                  *Product Code field is empty
                </small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="name"
              size="small"
              value={customerInfo?.name}
              placeholder="Enter name"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                paddingY: "0px",
                fontSize: "9pt",
                boxSizing: "border-box",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "12pt",
                  fontWeight: 600,
                }}
              >
                Phone
              </Typography>
              {customerInfo?.fieldErrorAlert == "phone" && (
                <small style={{ color: "#CB0101" }}>
                  *phone no field is empty
                </small>
              )}
            </Stack>

            <OutlinedInput
              type="number"
              name="phone"
              size="small"
              value={customerInfo?.phone}
              placeholder="Enter phone no"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                paddingY: "0px",
                fontSize: "9pt",
                boxSizing: "border-box",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "12pt",
                  fontWeight: 600,
                }}
              >
                Email
              </Typography>
              {customerInfo?.fieldErrorAlert == "email" && (
                <small style={{ color: "#CB0101" }}>
                  *email field is empty
                </small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="email"
              size="small"
              value={customerInfo?.email}
              placeholder="Enter email"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                paddingY: "0px",
                fontSize: "9pt",
                boxSizing: "border-box",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            {/* <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
              }}
            >
              Clear
            </Button> */}
            <Button
              variant="contained"
              color="success"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                handleOpen();
              }}
            >
              Update
            </Button>
            {/* {customerInfo?.phone &&
            customerInfo?.name &&
            customerInfo?.created_at ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  handleUpdate();
                }}
              >
                Update
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {}}
              >
                Update
              </Button>
            )} */}
          </Stack>
        </Grid>
      </Grid>
      <UpdateConfirmModal
        handleClose={handleClose}
        open={open}
        type1={"Update Customer Information Confirmation"}
        type2={"Do you want to update the customer information"}
        handleUpdate={handleUpdate}
      />
    </Box>
  );
};

export default UpdateCustomerInfo;
