import {
  Autorenew,
  AutoStories,
  AutoStoriesRounded,
  CheckroomRounded,
  HealthAndSafety,
  SaveAsRounded,
  SupportAgent,
  ThumbUpAlt,
} from "@mui/icons-material";
import { Container, Grid, Paper, Typography } from "@mui/material";
import { borderRadius, Box, Stack } from "@mui/system";
import React from "react";
import { GiLipstick } from "react-icons/gi";

const BannerFooter = () => {
  return (
    <Container sx={{ zIndex: 5 }}>
      <Paper sx={{ padding: "20px" }} elevation={1}>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Box>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    width: "45px",
                    height: "45px",
                    backgroundColor: "#24aeb1",
                    borderRadius: "50%",
                  }}
                >
                  <ThumbUpAlt
                    sx={{
                      color: "white",
                      fontSize: "20pt",
                    }}
                  />
                </Stack>
                <Stack sx={{ width: "75%" }}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 700 }}
                  >
                    99% Customer
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", fontWeight: 500 }}
                  >
                    Satisfiction
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    width: "45px",
                    height: "45px",
                    backgroundColor: "#eb3a7b",
                    borderRadius: "50%",
                  }}
                >
                  <Autorenew
                    sx={{
                      color: "white",
                      fontSize: "20pt",
                    }}
                  />
                </Stack>
                <Stack sx={{ width: "75%" }}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 700 }}
                  >
                    1 Year
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", fontWeight: 500 }}
                  >
                    Of Warranty
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    width: "45px",
                    height: "45px",
                    backgroundColor: "#00d084",
                    borderRadius: "50%",
                  }}
                >
                  <HealthAndSafety
                    sx={{
                      color: "white",
                      fontSize: "20pt",
                    }}
                  />
                </Stack>
                <Stack sx={{ width: "75%" }}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 700 }}
                  >
                    100% Safe
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", fontWeight: 500 }}
                  >
                    Online Shopping
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={6} md={3}>
            <Box>
              <Stack
                spacing={1}
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  sx={{
                    width: "45px",
                    height: "45px",
                    backgroundColor: "#51acf6",
                    borderRadius: "50%",
                  }}
                >
                  <SupportAgent
                    sx={{
                      color: "white",
                      fontSize: "20pt",
                    }}
                  />
                </Stack>
                <Stack sx={{ width: "75%" }}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 700 }}
                  >
                    24/7 Support
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", fontWeight: 500 }}
                  >
                    Always available
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default BannerFooter;
