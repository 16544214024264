import { ArrowDownward, Print } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  OutlinedInput,
  Paper,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useReactToPrint } from "react-to-print";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomInvoice from "./CustomInvoice";

const CustomOrder = ({
  shopInfo,
  orderId,
  setOrderId,
  newOrderToggle,
  setNewOrderToggle,
  newCustomerFieldInfo,
  addedItems,
  setAddedItems,setRender
}) => {
  console.log("addedItemsdsd:", addedItems);

  const printRef = useRef(null);
  const profileRef = useRef(null);
  const navigate = useNavigate();
  const [orderInfo, setOrderInfo] = useState({});
  // console.log("shopInfo:", shopInfo);
  const [customOrderCode, setCustomOrderCode] = useState(null);

  useEffect(() => {
    console.log("testQf:", orderId);
    fetch(
      `https://alqamaralzhabi.com/admin_api/booking_api/order_details.php?invoice_number=${orderId}`
    )
      .then((res) => res.json())
      .then((result) => {
        setOrderInfo(result[0]);
      });
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const handleGeneratePdf = () => {
    profileRef.current.save();
  };
  const handleChange = (e) => {
    setCustomOrderCode(e.target.value);
  };
  const handleClick = () => {
    navigate("/orderdetails/" + customOrderCode);
    fetch(
      `https://alqamaralzhabi.com/admin_api/booking_api/order_details.php?invoice_number=${customOrderCode}`
    )
      .then((res) => res.json())
      .then((result) => {
        setOrderInfo(result[0]);
      });
  };

  return (
    <Box>
      <Container>
        <Grid container spacing={0}>
          {/* {orderInfo?.pr_poster && ( */}
          <Grid item xs={12} md={12}>
            <Stack
              spacing={1}
              direction={"row"}
              justifyContent="right"
              alignItems={"center"}
              sx={{ height: "70px" }}
            >
              <Button
                startIcon={<ArrowDownward />}
                size="small"
                // onClick={toPdf}
                sx={{
                  paddingX: "10px",
                  color: "black",
                  bgcolor: "#e3dfdf",
                  fontSize: "9pt",
                  textTransform: "capitalize",
                  borderRadius: "30px",
                }}
                onClick={handleGeneratePdf}
              >
                Download
              </Button>
            </Stack>
            <PDFExport
              paperSize="A4"
              // margin="0.5cm"
              orientation="0"
              // scale={0.8}
              fileName={"receipt-" + orderId}
              ref={profileRef}
            >
              <Box sx={{ marginTop: "-30px" }} ref={printRef}>
                {orderInfo.order_id && (
                  <CustomInvoice
                    orderInfo={orderInfo}
                    shopInfo={shopInfo}
                    orderId={orderId}
                    newOrderToggle={newOrderToggle}
                    setNewOrderToggle={setNewOrderToggle}
                    newCustomerFieldInfo={newCustomerFieldInfo}
                    addedItems={addedItems}
                    setAddedItems={setAddedItems}
                    setRender={setRender}
                  />
                )}
              </Box>
            </PDFExport>
          </Grid>
          {/* )} */}
        </Grid>
      </Container>
    </Box>
  );
};

export default CustomOrder;
