import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { IconButton, Typography } from "@mui/material";
import { Delete, Download, Settings, Update } from "@mui/icons-material";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import SubModal from "./SubModal";

const columns = [
  { label: "Details" },
  { label: "Category" },

  { label: "Regular Price" },
  { label: "Offer Price" },
  { label: "Sub_state" },
  { label: "ProductCode" },
  { label: "Update" },
  { label: "Delete" },
];

export default function VariationListTable({ refer }) {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [render, setSender] = useState(0);
  const [subState, setSubState] = useState([]);
  const [open, setOpen] = useState(false);
  //   const [rowsPerPage, setRowsPerPage] = useState(10);
  const [allVariation, setAllVariation] = useState([]);
  console.log("allVariation:", allVariation);

  const handleSubModalOpen = () => {
    setOpen(true);
  };
  const handleSubModalClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    fetch(
      `https://alqamaralzhabi.com/admin_api/pr_variation_all.php?product_id=${refer}&puser=${parsedObject?.SopnoID}`
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("resulsdsdtc:", result);
        setAllVariation(result);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, []);

  const handleDelete = (refer) => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    const formData = new FormData();
    formData.append("user_id", parsedObject?.SopnoID);
    formData.append("token", "");
    formData.append("pro_refer", refer);
    fetch("https://alqamaralzhabi.com/admin_api/product_delete.php", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())

      .then((result) => {
        console.log("swalTips", result);
        if (result[0]?.result) {
          swal({
            title: result[0]?.resultState,
            text: result[0]?.resultMessage,
            icon: result[0]?.result,
            button: "Done",
          });
        }
        setSender(Math.floor(Math.random() * 10000000));
      })
      .catch((error) => console.log("newOrderApiError=> ", error));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //   const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(+event.target.value);
  //     setPage(0);
  //   };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: 488, overflow: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  size="small"
                  align="left"
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "9pt", fontWeight: 700 }}
                  >
                    {" "}
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allVariation?.map((item) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={item?.code}>
                  <>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item?.details ? item?.details : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item?.color ? item?.color : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item?.regular_price ? item?.regular_price : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item?.offer_price ? item?.offer_price : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell
                      align="left"
                      size="small"
                      onClick={() => {
                        setSubState(item?.pr_service);
                        handleSubModalOpen();
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item?.sub_state ? item?.sub_state : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item?.pro_refer ? item?.pro_refer : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" size="small">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          //   navigate(
                          //     "/updateProduct/" +
                          //       item?.pro_refer +
                          //       "/" +
                          //       item?.poster
                          //   );
                        }}
                      >
                        <Update fontSize="10pt" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          //   handleDelete(item?.pro_refer);
                        }}
                      >
                        <Delete fontSize="10pt" />
                      </IconButton>
                    </TableCell>
                  </>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={allVariation.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
      <SubModal
        open={open}
        handleClose={handleSubModalClose}
        subState={subState}
      />
    </Paper>
  );
}
