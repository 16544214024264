import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import ReactPlayer from "react-player";
import bg from "../../../images/order_bg/bg.jpg";
const OrderProcess = () => {
  return (
    <Box
      sx={{
        position: "relative",
        paddingY: { xs: "40px", md: "80px" },
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        component="img"
        sx={{
          // padding: "0 12px",
          width: "100%",
          height: "40vh",
          //   borderRadius: "50%",
          display: { xs: "none", md: "block" },
        }}
        alt="The house from the offer."
        // src="http://demo2.themelexus.com/medilazar/wp-content/uploads/2020/11/h2-bg-1.jpg"
        src={bg}
      />
      <Stack
        direction={"row"}
        justifyContent="center"
        alignItems={"center"}
        sx={{
          width: "100%",
          position: { xs: "relative", md: "absolute" },
          top: { xs: 0, md: 102 },
        }}
      >
        <Stack
          spacing={0}
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ width: { xs: "100%", md: "70%" }, boxSizing: "border-box" }}
        >
          <Stack
            sx={{
              width: { xs: "100%", md: "40%" },
              order: { xs: 1, md: 0 },
              paddingTop: { xs: "30px", md: 0 },
              // // paddingTop: { xs: "30px", md: 0 },
              // paddingLeft: { xs: "30px", md: 0 },
              paddingLeft: { xs: "10%", md: 0 },
              boxSizing: "border-box",
            }}
            spacing={1.5}
          >
            <Typography variant="p" sx={{ fontSize: "14pt", fontWeight: 600 }}>
              How to order? It's simple
            </Typography>
            <Stack spacing={1}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent="left"
                spacing={1}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent="center"
                  style={{
                    width: "30px",
                    height: "30px",
                    border: "2px solid #51ACF6",
                    borderRadius: "50%",
                  }}
                >
                  1
                </Stack>{" "}
                <Typography
                  variant="p"
                  sx={{ fontSize: "12pt", fontWeight: 500 }}
                >
                  Select a Product
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent="left"
                spacing={1}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent="center"
                  style={{
                    width: "30px",
                    height: "30px",
                    border: "2px solid #4bd7b8",
                    borderRadius: "50%",
                  }}
                >
                  2
                </Stack>{" "}
                <Typography
                  variant="p"
                  sx={{ fontSize: "12pt", fontWeight: 500 }}
                >
                  See Details
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent="left"
                spacing={1}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent="center"
                  style={{
                    width: "30px",
                    height: "30px",
                    border: "2px solid #e18cc8",
                    borderRadius: "50%",
                  }}
                >
                  3
                </Stack>{" "}
                <Typography
                  variant="p"
                  sx={{ fontSize: "12pt", fontWeight: 500 }}
                >
                  Send a Request
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent="left"
                spacing={1}
              >
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent="center"
                  style={{
                    width: "30px",
                    height: "30px",
                    border: "2px solid #ff500c",
                    borderRadius: "50%",
                  }}
                >
                  4
                </Stack>{" "}
                <Typography
                  variant="p"
                  sx={{ fontSize: "12pt", fontWeight: 500 }}
                >
                  Get one stop service easily{" "}
                </Typography>
              </Stack>
            </Stack>

            {/* <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 600 }}>
              পছন্দের পণ্য না পেলে কাস্টম অর্ডার ফরম পূরণ করে দিন।
            </Typography> */}
            <Typography variant="p" sx={{ fontSize: "12pt", fontWeight: 500 }}>
              We will contact with you
            </Typography>
          </Stack>
          <Stack
            sx={{ width: { xs: "100%", md: "50%" }, order: { xs: 0, md: 1 } }}
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
          >
            <Box
              sx={{
                width: "100%",
                height: { xs: "30vh", md: "40vh" },
                borderRadius: "12px",
              }}
            >
              <ReactPlayer
                //   sx={{ borderRadius: "12px" }}
                width="100%"
                height="100%"
                url="https://www.youtube.com/embed/SMKPKGW083c"
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default OrderProcess;
