import {
  Call,
  Language,
  LocalShipping,
  LocationOn,
  MedicalServices,
} from "@mui/icons-material";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { borderRadius, Box, Stack } from "@mui/system";
import React from "react";
import playstorelogo from "../../../images/playStore.png";
import appstorelogo from "../../../images/appStore.png";
import { useState } from "react";
import { useEffect } from "react";
import { statisticsApi } from "../../Api/Api";
import StatisticItem from "./StatisticItem";

const Statistics = () => {
  const [data, setData] = useState([]);
  console.log("datasdadad:", data);

  useEffect(() => {
    fetch(statisticsApi)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, []);
  return (
    <Box sx={{ bgcolor: "#e8f0f5de" }}>
      <Container sx={{ bgcolor: "#e8f0f5de" }}>
        <Paper
          sx={{
            padding: {
              xs: "10px",
              md: "40px",
              backgroundColor: "#e8f0f5de !important",
            },
          }}
          elevation={0}
        >
          <Grid container spacing={2} sx={{ paddingX: "50px" }}>
            {data.map((element) => (
              <Grid item xs={6} sm={6} md={3} key={element.id}>
                <StatisticItem data={element} />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Container>
    </Box>
  );
};

export default Statistics;
