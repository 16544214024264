import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import CommentIcon from "@mui/icons-material/Comment";
import IconButton from "@mui/material/IconButton";
import { Collapse, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Box, Stack } from "@mui/system";
import { KeyboardArrowDown } from "@mui/icons-material";
import "./CategoryList.css";
const list = [
  { title: "Mobile", id: 1, value: "mobile" },
  { title: "Laptop", id: 2, value: "laptop" },
  { title: "Desktop", id: 3, value: "desktop" },
  { title: "Router", id: 4, value: "router" },
  { title: "Access Point", id: 5, value: "accessPoint" },
  { title: "Switch", id: 6, value: "switch" },
];

const CategoryList = ({
  setProductType,
  setProductListData,
  page,
  productType,
}) => {
  const focus = useRef("");
  const [toggle, setToggle] = useState(true);
  const [categoryHeight, setCategoryHeight] = useState("");
  const [categoryListData, setCategoryListData] = useState(list);
  const [subCategoryListData, setSubCategoryListData] = useState([]);
  console.log("subCategoryListData:", subCategoryListData);
  const [source, setSource] = useState("category");
  const [sourceValueId, setSourceValueId] = useState("");
  console.log("categoryListData:", categoryListData);
  const [open, setOpen] = React.useState(false);
  console.log("open:", open);

  useEffect(() => {
    handleCategoryHeight(focus.current.offsetHeight);
  }, [categoryListData[0], open]);

  useEffect(() => {
    if (source === "category") {
      fetch(
        `https://alqamaralzhabi.com/admin_api/product_type_list.php?category=${productType}&page_no=${page}`
      )
        .then((res) => res.json())
        .then((result) => {
          setProductListData(result);
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    } else {
      fetch(
        `https://alqamaralzhabi.com/admin_api/product_list_brand.php?category=${sourceValueId?.category}&brand=${sourceValueId?.brand}&page_no=${page}&puser=6d6c3669667a7839`
      )
        .then((res) => res.json())
        .then((result) => {
          setProductListData(result);
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    }
  }, [page]);

  const handleCategoryHeight = (value) => {
    setCategoryHeight(value);
  };
  const handleToggle = (event) => {
    setToggle(!toggle);
  };
  console.log("categoriCategoryHeight:", categoryHeight);

  const handleSubCategory = (categoryName) => {
    console.log("categoryName:", categoryName);
    // setFirstLoadIndegator(false);

    if (open === categoryName) {
      setOpen(false);
    } else {
      fetch(
        `https://alqamaralzhabi.com//admin_api/product_type_brand.php?category=${categoryName}&puser=6d6c3669667a7839`
      )
        .then((res) => res.json())
        .then((result) => {
          setSubCategoryListData(result);
          if (result[0]?.id) {
            setOpen(categoryName);
          }
        })
        .catch((error) => console.log("ServiceApiError=> ", error));
    }
    setSource("category");
    setSourceValueId(categoryName);
  };
  const handleSubCatItems = (brand, category) => {
    setSource("subCategory");
    setSourceValueId({ brand, category });

    fetch(
      `https://alqamaralzhabi.com/admin_api/product_list_brand.php?category=${category}&brand=${brand}&puser=6d6c3669667a7839`
    )
      .then((res) => res.json())
      .then((result) => {
        setProductListData(result);
        if (result[0]?.id) {
          setOpen(category);
        }
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  };
  return (
    <Box>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="p" sx={{ fontSize: "13pt", fontWeight: 600 }}>
          Categories
        </Typography>
        <IconButton
          size="small"
          onClick={() => {
            handleToggle();
          }}
        >
          <KeyboardArrowDown
            fontSize="inherit"
            color="black"
            sx={{
              transform: toggle === false ? "rotate(-180deg)" : "",
              transition: "transform 1s",
            }}
          />
        </IconButton>
      </Stack>
      <Box
        className={toggle === false ? "lowCategoryHeight" : ""}
        sx={{
          height: "auto",
          // height:
          //   categoryHeight && toggle ? `${categoryHeight}px !important` : "0px",
          overflow: "hidden",
          transition: "height 1s",
        }}
      >
        <List sx={{ width: "100%", bgcolor: "background.paper" }} ref={focus}>
          {categoryListData?.map((value) => (
            <>
              {" "}
              <ListItem
                key={value?.title}
                disableGutters
                sx={{ padding: 0, cursor: "pointer" }}
                secondaryAction={
                  <IconButton
                    aria-label="comment"
                    size="small"
                    sx={{
                      bgcolor: "#60C2A1",
                      color: "white",
                      // display: { xs: "none", md: "block" },
                    }}
                    onClick={() => {
                      handleSubCategory(value?.value);
                    }}
                  >
                    {" "}
                    <KeyboardArrowDown
                      color="black"
                      sx={{
                        fontSize: "12pt",
                        transform:
                          open === value?.value && open != false
                            ? "rotate(-180deg)"
                            : "",
                        transition: "transform 2s",
                      }}
                    />
                  </IconButton>
                }
              >
                <ListItemText
                  onClick={() => {
                    setProductType(value.value);
                    setSource("category");
                  }}
                  primary={`${value.title}`}
                  sx={{}}
                />
              </ListItem>
              <Collapse in={open === value?.value} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {subCategoryListData.map((sub) => (
                    <ListItem
                      key={sub.title}
                      disableGutters
                      sx={{ padding: 0, cursor: "pointer" }}
                    >
                      <ListItemText
                        primary={`${sub?.brand}`}
                        sx={{ paddingLeft: "15px" }}
                        onClick={() => {
                          handleSubCatItems(sub?.brand, sub?.category);
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default CategoryList;
