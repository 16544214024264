import React, { useEffect } from "react";
import OTPimage from "../../../images/OTP.jpg";
import {
  Box,
  Button,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Classes from "./OTP.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useRef } from "react";
import { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import moment from "moment/moment";
import { getRemainingTimeUntillMsTimestamp } from "./DateCountdown/CountdownTimerUtils";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const defaultRemainingTime = {
  seconds: 0,
  minutes: 0,
  hours: 0,
  days: 0,
};
const OTP = ({ afterHit, otpInfo, handleSendOTP }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");

  const [time, setTime] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const itemsRef = useRef([]);
  const [userInput, setUserInput] = useState([]);
  const [code, setCode] = useState("");
  const [seconds, setSeconds] = useState("");
  const [inputFields, setInputFields] = useState([
    { no: 1, code: "" },
    { no: 2, code: "" },
    { no: 3, code: "" },
    { no: 4, code: "" },
    { no: 5, code: "" },
    { no: 6, code: "" },
  ]);

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [countDownDate, setCountDownDate] = useState("");
  console.log("countDownDate:", countDownDate);
  const [remainingTime, setRemainingTime] = useState(defaultRemainingTime);

  useEffect(() => {
    setTimeout(() => {
      setTime(true);
    }, 120000);
    let oldDateObj = new Date();
    let newDateObj = moment(oldDateObj).add(121, "s").toDate();
    setCountDownDate(newDateObj.getTime());
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateRemainingTime(countDownDate);
    }, 1000);
    return () => clearTimeout(intervalId);
  }, [countDownDate]);

  function updateRemainingTime(countdown) {
    setRemainingTime(getRemainingTimeUntillMsTimestamp(countdown));
  }

  const extendSessionTime = () => {
    setTime(false);
    setTimeout(() => {
      setTime(true);
    }, 120000);

    let oldDateObj = new Date();
    let newDateObj = moment(oldDateObj).add(121, "s").toDate();
    setCountDownDate(newDateObj.getTime());
  };

  useEffect(() => {
    const sumWithInitial = inputFields?.reduce(
      (accumulator, currentValue) => accumulator + currentValue?.code,
      ""
    );
    setCode(sumWithInitial);
  }, [inputFields]);

  const handleVarify = () => {
    let newObject = localStorage.getItem("wmData");
    let parsedObject = JSON.parse(newObject);
    const formData = new FormData();
    formData.append("token", code);
    formData.append("order_id", otpInfo?.order_id);
    formData.append("phone", otpInfo?.phone);
    formData.append("mail", otpInfo?.mail);
    formData.append("user", parsedObject?.SopnoID);

    axios
      .post(
        `https://alqamaralzhabi.com/admin_api/pay_api/token_verify.php`,
        formData
      )
      .then((res) => {
        console.log(res);
        console.log(res.data);
        // let updataData = res.data.replace("\n\n\n\n\n ", "");
        console.log("updataData", res);
        if (res.data?.m_type == "success") {
          afterHit();
        }
        if (res.data?.m_type == "warning") {
          setAlertMessage(res.data?.errMsg);

          handleClick();
        }
      });
  };

  const codeChangeHandler = (index, event) => {
    const element = event.target;
    const nextSibling = element.nextElementSibling;
    nextSibling ? nextSibling.focus() : element.blur();
    let data = [...inputFields];
    data[index][event.target.name] = event.target.value;
    setInputFields(data);
  };
  const codeRemoveHandler = (index, event) => {
    console.log("event434343:", event.target.value);
    if (event.key === "Delete" || event.key === "Backspace") {
      const element = event.target;
      // const preSibling = element.previousElementSibling;
      const preSibling = element;
      console.log("preSibling:", preSibling);

      let data = [...inputFields];
      data[index][event.target.name] = "";
      setInputFields(data);
      preSibling ? preSibling.focus() : element.blur();
    }
  };

  const codeInputFields =
    //  new Array(6)
    //   .fill(0)
    inputFields.map((item, index) => (
      <input
        ref={(ref) => itemsRef.current.push(ref)}
        name={`code`}
        key={index}
        value={item?.code}
        style={{ width: "30px", height: "30px", textAlign: "center" }}
        onChange={(event) => codeChangeHandler(index, event)}
        onKeyDown={(event) => codeRemoveHandler(index, event)}
        maxLength={1}
      />
    ));

  return (
    <Box
      sx={{
        minWidth: "275px",
        padding: "1.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        {" "}
        <img
          src={OTPimage}
          alt="OTP"
          style={{
            width: "200px",
          }}
        />
        <Typography
          variant="h4"
          gutterBottom
          component="div"
          style={{
            fontWeight: "bold",
            color: "gray",
          }}
        >
          Verification Code
        </Typography>
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          style={{
            color: "black",
            fontWeight: "bold",
          }}
        >
          We have sent a verification code to your Mobile Number. {seconds}
        </Typography>
        <Typography variant="button" display="block" gutterBottom>
          {/* number comes from the database */}
        </Typography>
        <Typography variant="button" display="block" gutterBottom>
          {/* number comes from the database */}
        </Typography>
        {/* <TextField
          id="outlined-basic"
          label="Enter OTP"
          variant="outlined"
          style={{
            // color: "black",
            // fontWeight: "bold",
            width: "300px",
          }}
          onChange={(e) => setOTP(e.target.value)}
        /> */}
        {time && (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <span
              style={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              Didn't receive the code?
            </span>
            <span
              className={`${Classes.resendOTP}`}
              onClick={() => {
                handleSendOTP();
                extendSessionTime();
              }}
            >
              Resend Code
            </span>
          </Box>
        )}
        {!time && (
          <Stack
            spacing={1}
            direction={"row"}
            alignItems="center"
            justifyContent={"left"}
            mb={2}
          >
            <Paper>
              <Stack
                spacing={0}
                sx={{
                  width: "40px",
                  height: "40px",
                }}
                justifyContent="center"
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    fontSize: "12pt",
                    fontWeight: 600,
                    lineHeight: "18px",
                  }}
                >
                  {remainingTime.minutes}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "10pt",
                    fontWeight: 500,
                    color: "gray",
                    lineHeight: "12px",
                  }}
                >
                  min
                </Typography>
              </Stack>
            </Paper>
            <Paper>
              <Stack
                spacing={0}
                sx={{
                  width: "40px",
                  height: "40px",
                }}
                justifyContent="center"
                alignItems={"center"}
              >
                <Typography
                  sx={{
                    fontSize: "12pt",
                    fontWeight: 600,
                    lineHeight: "18px",
                  }}
                >
                  {remainingTime.seconds}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "10pt",
                    fontWeight: 500,
                    color: "gray",
                    lineHeight: "12px",
                  }}
                >
                  sec
                </Typography>
              </Stack>
            </Paper>
          </Stack>
        )}
        <Stack direction="row" alignItems="center" justifyContent={"center"}>
          {codeInputFields}
        </Stack>
        {code?.length == 6 ? (
          <Box>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2, mb: 6 }}
              onClick={handleVarify}
              type="submit"
            >
              Verify
            </Button>

            {/* <PaymentMethodCashInModal
            open1={open1}
            handleOpen1={handleOpen1}
            handleClose1={handleClose1}
          /> */}
          </Box>
        ) : (
          <Box>
            <Button
              disabled
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2, mb: 6 }}
              type="submit"
            >
              Verify
            </Button>

            {/* <PaymentMethodCashInModal
          open1={open1}
          handleOpen1={handleOpen1}
          handleClose1={handleClose1}
        /> */}
          </Box>
        )}
        {alertMessage && <Alert severity="warning">{alertMessage}</Alert>}
      </Box>
    </Box>
  );
};

export default OTP;
