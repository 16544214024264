import {
  Box,
  Button,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import swal from "sweetalert";

const CustomerInfoForm = ({
  setAddNewBtn,
  setNewOrderToggle,
  setnewCustomerInfo,
  newCustomerInfo,
  setNewCustomerFieldInfo,
  newCustomerFieldInfo,
}) => {
  const clear = () => {
    setnewCustomerInfo({});
    setNewCustomerFieldInfo({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "mobile") {
      isFieldValid = /[0-9]+/.test(event.target.value);
    }
    if (event.target.name === "email") {
      isFieldValid = true;
    }
    if (event.target.name === "name") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }
    if (event.target.name === "date") {
      isFieldValid = /^\S+$/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = newCustomerFieldInfo;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setNewCustomerFieldInfo({ ...updateField });

      const newnewCustomerInfo = newCustomerInfo;
      newnewCustomerInfo[event.target.name] = event.target.value;
      setnewCustomerInfo({ ...newnewCustomerInfo });
    }
    if (!isFieldValid) {
      const newField = { ...newCustomerFieldInfo };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setNewCustomerFieldInfo(newField);
    }
  };
  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Date
              </Typography>
              {newCustomerFieldInfo.fieldErrorAlert === "date" && (
                <small style={{ color: "#CB0101" }}>*Enter date</small>
              )}
            </Stack>

            <OutlinedInput
              type="date"
              name="date"
              size="small"
              placeholder="Enter date"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Name
              </Typography>
              {newCustomerFieldInfo.fieldErrorAlert == "name" && (
                <small style={{ color: "#CB0101" }}>
                  *Product Code field is empty
                </small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="name"
              size="small"
              placeholder="Enter name"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                paddingY: "0px",
                fontSize: "9pt",
                boxSizing: "border-box",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Mobile
              </Typography>
              {newCustomerFieldInfo.fieldErrorAlert == "mobile" && (
                <small style={{ color: "#CB0101" }}>
                  *Mobile no field is empty
                </small>
              )}
            </Stack>

            <OutlinedInput
              type="number"
              name="mobile"
              size="small"
              placeholder="Enter mobile no"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                paddingY: "0px",
                fontSize: "9pt",
                boxSizing: "border-box",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Email
              </Typography>
              {newCustomerFieldInfo.fieldErrorAlert == "email" && (
                <small style={{ color: "#CB0101" }}>
                  *email field is empty
                </small>
              )}
            </Stack>

            <OutlinedInput
              type="text"
              name="email"
              size="small"
              placeholder="Enter email"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                paddingY: "0px",
                fontSize: "9pt",
                boxSizing: "border-box",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {newCustomerFieldInfo.mobile &&
            newCustomerFieldInfo.name &&
            newCustomerFieldInfo.date ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  setNewOrderToggle(true);
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {}}
              >
                Next
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerInfoForm;
