import { Container, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const FooterBottom = () => {
  return (
    <Box sx={{ bgcolor: "#141414", marginBottom: 0 }}>
      <Container>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ height: "10vh" }}
        >
          <Typography
            variant="p"
            sx={{
              color: "white",
              fontSize: "10pt",
              fontWeight: 500,
            }}
          >
            Copyright © 2022 alqamaralzhabi.com
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "white",
              fontSize: "10pt",
              fontWeight: 500,
            }}
          >
            Developed By Ghorami Technology
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
};

export default FooterBottom;
