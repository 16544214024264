import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProductCardFlat from "../components/ProductList Components/ProductCardFlat.js";
import CategoryList from "../components/ProductList Components/CategoryList.js";
import { Apps, FormatListBulleted } from "@mui/icons-material";
import ProductCard from "../components/ProductList Components/ProductCard.js";

const ProductList = () => {
  const [productType, setProductType] = useState("mobile");
  const [productListData, setProductListData] = useState([]);
  const [age, setAge] = useState("");
  const [viewMode, setViewMode] = useState("grid");
  const [page, setPage] = useState(1);

  const handlePageChange = (event, value) => {
    // setFirstLoadIndegator(false);
    setPage(value);
  };
  useEffect(() => {
    fetch(
      `https://alqamaralzhabi.com/admin_api/product_type_list.php?category=${productType}`
    )
      .then((res) => res.json())
      .then((result) => {
        setProductListData(result);
      })
      .catch((error) => console.log("ServiceApiError=> ", error));
  }, [productType]);

  console.log("productListDatahhhhhh:", productListData);
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Box mb={6}>
      <Container sx={{ marginTop: "2%" }}>
        <Stack direction="row">
          <Paper
            elevation={3}
            sx={{
              width: "30%",
              boxSizing: "border-box",
              height: "102vh",
              padding: "20px",
            }}
          >
            <Stack>
              <CategoryList
                page={page}
                productType={productType}
                setProductType={setProductType}
                setProductListData={setProductListData}
              />
              <Divider light sx={{ marginBottom: "10px" }} />
            </Stack>
          </Paper>
          <Stack
            justifyContent="flex-start"
            sx={{
              width: "70%",
              padding: "2%",
              marginTop: { xs: "52px", md: "0px" },
            }}
          >
            {" "}
            <Stack direction="row" justifyContent="right" alignItems="center">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
                sx={{ display: { xs: "none", md: "block" }, width: "50%" }}
              >
                <Typography variant="p">Sort by :</Typography>

                <Select
                  sx={{ minWidth: 100 }}
                  size="small"
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">
                    <em>Default Sorting</em>
                  </MenuItem>
                  <MenuItem value={10}>Sort by popularity</MenuItem>
                  <MenuItem value={30}>Sort by newness</MenuItem>
                  <MenuItem value={30}>Sort by average rating</MenuItem>
                  <MenuItem value={30}>Sort by price: low to high</MenuItem>
                  <MenuItem value={30}>Sort by price: high to low</MenuItem>
                </Select>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent={"right"}
                sx={{ width: "50%" }}
              >
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ marginRight: "5px" }}
                  onClick={() => {
                    setViewMode("grid");
                  }}
                >
                  <Apps />
                </Button>
                <Button
                  size="small"
                  onClick={() => {
                    setViewMode("list");
                  }}
                >
                  <FormatListBulleted />
                </Button>
              </Stack>
            </Stack>
            <Box sx={{ flexGrow: 1, marginTop: "10px" }}>
              <Grid container rowSpacing={{ xs: 2, md: 2 }}>
                {viewMode === "list" &&
                  productListData?.map((data) => (
                    <Grid item xs={12} sm={12} md={12} key={data.pro_pic}>
                      <ProductCardFlat data={data} />
                    </Grid>
                  ))}
                {viewMode === "grid" &&
                  productListData?.map((data) => (
                    <Grid item xs={12} sm={6} md={4} key={data.pro_pic}>
                      <ProductCard data={data} />
                    </Grid>
                  ))}
              </Grid>
            </Box>
            <Divider light sx={{ marginTop: "30px", marginBottom: "20px" }} />
            <Pagination
              count={10}
              page={page}
              onChange={handlePageChange}
              variant="outlined"
              color="primary"
              sx={{ margin: "auto" }}
            />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default ProductList;
