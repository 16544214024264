import { ZoomInOutlined, ZoomOutOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";
import { Box, Stack } from "@mui/system";
import React from "react";
import { useState } from "react";

const Organization = () => {
  const [zoom, setZoom] = useState(false);
  return (
    <Box mb={zoom ? 10 : 5} mt={{ xs: zoom ? 16 : 8, md: 2 }}>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"center"}
        sx={{ width: "100%", position: "relative" }}
      >
        <IconButton
          size="medium"
          sx={{
            display: { xs: "block", md: "none", zIndex: 10 },
            position: "absolute",
            top: zoom ? "-25px" : "20px",
            right: "50px",
          }}
          onClick={() => {
            setZoom(!zoom);
          }}
        >
          {!zoom ? (
            <ZoomInOutlined
              sx={{
                fontSize: "17pt",
              }}
            />
          ) : (
            <ZoomOutOutlined
              sx={{
                fontSize: "17pt",
              }}
            />
          )}
        </IconButton>
        <Box
          component={"img"}
          src="https://alqamaralzhabi.com/admin_api/al_qamar_organogram.png"
          sx={{
            width: "60%",
            transform: zoom ? "scale(1.7)" : "scale(1)",
            transition: "transform .2s",
          }}
        />
      </Stack>
    </Box>
  );
};

export default Organization;
