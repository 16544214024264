import { Box } from "@mui/system";
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./Home";

import Contact from "./Contact";

import About from "./About";
import ProductList from "./ProductList";
import ProductUpload from "./ProductUpload";
import PrivateRoute from "./PrivateRoute";
import ProductDetails from "./ProductDetails";
import Organization from "./Organization";
import ShoppingCartPage from "./ShoppingCartPage";
import Booknow from "../components/Booknow Component/Booknow";
import BooknowPage from "./BooknowPage";
import OrderDetailsPage from "./OrderDetailsPage";
import AdminLogin from "./AdminLogin";
import Login from "./Login";
import Search from "./Search";
import UpdateOrder from "../components/ProductUpload Components/Update order/UpdateOrder";
import ProductUpdate from "../components/ProductUpload Components/Update Product/ProductUpdate";
import PaymentSuccess from "../components/Billing/Success/PaymentSuccess";
import OTP from "../components/Billing/OTP/OTP";

const Pages = () => {
  return (
    <Box>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<ProductList />} />
        <Route path="/organization" element={<Organization />} />
        {/* <Route path="/success/:paymentId" element={<PaymentSuccess />} /> */}
        <Route path="/success" element={<PaymentSuccess />} />
        <Route path="/otp" element={<OTP />} />
        <Route path="/search/:srcCategory/:srcName" element={<Search />} />
        <Route
          path="/productdetails/:refer/:poster"
          element={<ProductDetails />}
        />

        <Route
          path="/upload"
          element={
            <PrivateRoute>
              <ProductUpload />
            </PrivateRoute>
          }
        />

        <Route path="/shoppingcart" element={<ShoppingCartPage />} />
        <Route
          path="/shoppingcart:id:quantity"
          element={<ShoppingCartPage />}
        />
        <Route path="/booknow/:orderId" element={<BooknowPage />} />
        <Route
          path="/booknow/:DPPprice/:DPPquantity/:orderId"
          element={<BooknowPage />}
        />
        <Route path="/orderdetails/:orderId" element={<OrderDetailsPage />} />
        <Route path="/orderdetails" element={<OrderDetailsPage />} />
        <Route path="/updateOrder/:orderId" element={<UpdateOrder />} />
        <Route
          path="/updateProduct/:refer/:poster"
          element={<ProductUpdate />}
        />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/adminLogin" element={<AdminLogin />} />
      </Routes>
    </Box>
  );
};

export default Pages;
