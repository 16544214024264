import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { IconButton, Typography } from "@mui/material";
import {
  Article,
  Delete,
  Download,
  Settings,
  Update,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import UpdateConfirmModal from "../../Update order/UpdateConfirmModal";

const columns = [
  { label: "Name" },
  { label: "Total Item" },
  { label: "Total Price" },
  { label: "Total Quantity" },
  { label: "Total Amount" },
  { label: "Order Date" },
  { label: "Details" },
  { label: "Delete" },
  { label: "Update" },
];

export default function OrderTable({ allOrders, setRender, render }) {
  const navigate = useNavigate();
  console.log("allOrders:", allOrders);
  const [page, setPage] = useState(0);
  //   const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDeleteOrder = (order_id) => {
    const formData = new FormData();
    formData.append("invoice_id", order_id);
    fetch(
      "https://alqamaralzhabi.com/admin_api/booking_api/custom_order_delete.php",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.json())
      .then((result) => {
        console.log("result:", result);
        if (result?.m_result) {
          swal({
            title: result?.m_result,
            text: result?.q_message,
            icon: result?.m_type,
            button: "Done",
          });
          handleClose();
          setRender(!render);
        }
      });
  };
  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ height: 488, overflow: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={index}
                  size="small"
                  align="left"
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography
                    variant="p"
                    sx={{ fontSize: "9pt", fontWeight: 700 }}
                  >
                    {" "}
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allOrders?.map((item) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={item.code}>
                  <>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item?.customer_info[0]?.name
                          ? item?.customer_info[0]?.name
                          : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item?.total_item ? item?.total_item : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item?.total_price ? item?.total_price : "-"}
                      </Typography>
                    </TableCell>

                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item?.total_quantity ? item?.total_quantity : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item?.total_amount ? item?.total_amount : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <Typography variant="p" sx={{ fontSize: "8pt" }}>
                        {item?.time_stamp ? item?.time_stamp : "-"}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          navigate("/orderdetails/" + item?.order_id);
                        }}
                      >
                        <Article fontSize="10pt" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          setSelectedOrderId(item?.order_id);
                          handleOpen();
                        }}
                      >
                        <Delete fontSize="10pt" />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left" size="small">
                      <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          navigate("/updateOrder/" + item?.order_id);
                        }}
                      >
                        <Update fontSize="10pt" />
                      </IconButton>
                    </TableCell>
                  </>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <UpdateConfirmModal
        handleClose={handleClose}
        open={open}
        type1={"Delete Order Confirmation"}
        type2={"Do you want to delete the order"}
        selectedOrderId={selectedOrderId}
        handleUpdate={handleDeleteOrder}
      />
      {/* <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={allOrders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
