import {
  AddShoppingCart,
  FavoriteBorder,
  HomeOutlined,
  PictureAsPdf,
  ShoppingBasket,
  Square,
  Star,
  StarHalf,
} from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Rating,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { Container, Stack } from "@mui/system";
import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import BookOverview from "../components/HomePageComponents/BookSlider/BookOverview";
import ProductOveview from "../components/ProductDetailsComponents/ProductOveview";

const ProductDetails = () => {
  let { refer, poster } = useParams();
  const [productDetails, setProductDetails] = useState({});
  console.log("productDetails:", productDetails);
  const [clickedPic, setClickedPic] = useState("");

  console.log("detaixcxcxls", productDetails);

  useEffect(() => {
    fetch(
      `https://alqamaralzhabi.com/admin_api/product_details.php?pro_ref=${refer}&puser=${poster}`
    )
      .then((res) => res.json())
      .then((result) => {
        setProductDetails(result[0]);
      })
      .catch((error) => console.log("FetchUserApiError=> ", error));
  }, []);
  console.log("productDetails in pd:", productDetails);

  return (
    <Box mb={{ md: 10, xs: 25 }} sx={{ height: "auto" }}>
      <ProductOveview
        productDetails={productDetails}
        setProductDetails={setProductDetails}
        refer={refer}
      />
    </Box>
  );
};

export default ProductDetails;
