import React from "react";
export const masterApi = "https://alqamaralzhabi.com";

export const masterApi2 = "https://ghorami.com";

// export const nagadApi = "https://yeapbe.com/ghpay/nagad/index.php";
export const nagadApi = "https://yeapbe.com/test/nagad/index.php";

// Booknow Page Api
export const countryListApi =
  masterApi2 + "/profile/login/api_booking/country_list.php";

export const fetchUserApi =
  masterApi2 + "/profile/login/api_booking/fetch_user_info.php";

export const newOrderApi = masterApi + "/api/booking_api/order_new.php";

export const bannerApi = "https://alqamaralzhabi.com/admin_api/top_banner.php";

export const allProductApi = masterApi + "/api/web_api/product_all.php";

export const allIslamicBookApi =
  masterApi + "/api/web_api/product_all_Islamic_book.php";

export const newBookApi = masterApi + "/api/web_api/product_all_new_book.php";

export const allPopularBookApi =
  masterApi + "/api/web_api/product_all_popular_book.php";

export const allGeneralApi = masterApi + "/api/web_api/product_all_general.php";

export const allStationaryApi =
  masterApi + "/api/web_api/product_all_stationary.php";

export const allCosmeticsApi =
  masterApi + "/api/web_api/product_all_cosmetics.php";

export const allDressApi = masterApi + "/api/web_api/product_all_dress.php";

export const BookTypeApi = masterApi + "/api/web_api/product_type_book.php";

export const ProductTypeGeneralApi =
  masterApi + "/api/web_api/product_type_general.php";

export const ClothTypeApi = masterApi + "/api/web_api/product_type_dress.php";

export const addBannerTopApi = masterApi + "/api/web_api/add_banner_top.php";

export const topDealApi = masterApi + "/api/web_api/topDeal.php";

export const BrandsApi = masterApi + "/api/web_api/product_brand_all.php";

export const BooksApi = masterApi + "/api/web_api/store_all_book.php";

export const filterApi = masterApi + "/api/web_api/store_all_book.php";

export const dressesApi = masterApi + "/api/web_api/store_all_dress.php";

export const generalAllApi = masterApi + "/api/web_api/store_all_general.php";

export const preOrderApi = masterApi + "/api/web_api/store_all_preorder.php";

export const writerApi = masterApi + "/api/web_api/all_writer.php";

export const publisherApi = masterApi + "/api/web_api/all_publisher.php";

export const generalBookApi =
  masterApi + "/api/web_api/store_all_book_general.php";

export const CosmeticsApi = masterApi + "/api/web_api/store_all_cosmetics.php";

export const stationeryApi =
  masterApi + "/api/web_api/store_all_stationary.php";

export const statisticsApi = masterApi + "/api/web_api/web_statistics.php";

// All BOOKPAGES categories leftSide list apis

export const BookTypeCatApi = masterApi + "/api/web_api/product_type_book.php";

export const writerCatApi =
  masterApi + "/api/web_api/filter_api/get_or_all_writer.php";

export const publisherCatApi =
  masterApi + "/api/web_api/filter_api/get_p_all_publisher.php";

export const MinimumOrderApi =
  masterApi + "/api/web_api/filter_api/min_order_que.php";

export const subCategoriesApi =
  masterApi + "/api/web_api/filter_api/get_c_subcategory.php";

export const CategoriesApi =
  masterApi + "/api/web_api/product_category_all.php";

// All CLOTHPAGES categories leftSide list apis

export const ClothTypeCatApi =
  masterApi + "/api/web_api/filter_api/get_all_dress_type.php";

export const ageCatApi =
  masterApi + "/api/web_api/filter_api/get_or_all_age.php";

export const colorCatApi =
  masterApi + "/api/web_api/filter_api/get_or_all_color.php";

export const febricsCatApi =
  masterApi + "/api/web_api/filter_api/get_or_all_fabrics.php";

export const brandCatApi =
  masterApi + "/api/web_api/filter_api/get_p_all_brand.php";

export const originCatApi =
  masterApi + "/api/web_api/filter_api/get_or_all_origin.php";

// All CosmeticsPAGES categories leftSide list apis

export const cosmeticsSubCatApi =
  masterApi + "/api/web_api/filter_api/get_c_subcategory.php";

export const cosmeticsTypeCatApi =
  masterApi + "/api/web_api/filter_api/get_all_cosmetics_type.php";

export const cosmeticBrandCatApi =
  masterApi + "/api/web_api/filter_api/get_p_all_cosmeticsbrand.php";

export const cosmeticMinOrderCatApi =
  masterApi + "/api/web_api/filter_api/min_order_que.php";

// All GeneralPAGES categories leftSide list apis

export const GeneralSubCatApi =
  masterApi + "/api/web_api/filter_api/get_c_subcategory.php";

export const GeneralTypeCatApi =
  masterApi + "/api/web_api/filter_api/get_all_generalproduct_type.php";

export const GeneralBrandCatApi =
  masterApi + "/api/web_api/filter_api/get_p_all_other_brand.php";

export const GeneralMinOrderCatApi =
  masterApi + "/api/web_api/filter_api/min_order_que.php";

const Api = () => {
  return <div></div>;
};

export default Api;
