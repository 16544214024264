import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./NewOrder.css";

const NewOrderForm = ({ setAddNewBtn, setAddedItems, addedItems }) => {
  const fieldRef = useRef();
  const [newOrderInfo, setNewOrderInfo] = useState({});
  const [inputField, setInputField] = useState({});
  const [id, setId] = useState(0);

  const handleRand = () => {
    const updateField = inputField;
    updateField.pro_refer = Math.floor(Math.random() * 10000000000);
    setInputField({ ...updateField });
  };

  const handleAdd = () => {
    setId(id + 1);
    const UpdateOrderInfo = newOrderInfo;
    UpdateOrderInfo.id = id;
    UpdateOrderInfo.itemTotal =
      parseFloat(inputField.price) * parseFloat(inputField.quantity);
    setNewOrderInfo({ ...UpdateOrderInfo });
    const newList = addedItems;
    setAddedItems([...newList, newOrderInfo]);

    // fieldRef.current.children.value = "";
    // console.log("fieldRef.current:", fieldRef.current.children);
  };

  const clear = () => {
    setNewOrderInfo({});
    setInputField({});
  };
  const handleChange = (event) => {
    let isFieldValid = false;

    if (event.target.name === "itemTotal") {
      isFieldValid = /[0-9]+/.test(event.target.value);
    }
    if (event.target.name === "price") {
      isFieldValid = /[0-9]+/.test(event.target.value);
    }
    if (event.target.name === "pro_refer") {
      isFieldValid = /[a-z]|[A-Z]|[0-9]/.test(event.target.value);
    }
    if (event.target.name === "quantity") {
      isFieldValid = /[0-9]+/.test(event.target.value);
    }
    if (event.target.name === "title") {
      isFieldValid = /[a-z]|[A-Z]/.test(event.target.value);
    }

    if (isFieldValid) {
      const updateField = inputField;
      updateField[event.target.name] = event.target.value;
      updateField.fieldErrorAlert = "";
      setInputField({ ...updateField });

      const newnewOrderInfo = newOrderInfo;
      newnewOrderInfo[event.target.name] = event.target.value;
      setNewOrderInfo({ ...newnewOrderInfo });
      if (event.target.name === "price") {
        const updateField = inputField;
        updateField.regular_price = event.target.value;
        setInputField({ ...updateField });
        updateField.currency = "AED";
        setInputField({ ...updateField });
        updateField.discount = 0;
        setInputField({ ...updateField });

        const newnewOrderInfo = newOrderInfo;
        newnewOrderInfo.regular_price = event.target.value;
        setNewOrderInfo({ ...newnewOrderInfo });
        updateField.currency = "AED";
        setNewOrderInfo({ ...updateField });
        updateField.discount = 0;
        setNewOrderInfo({ ...updateField });
      }
    }
    if (!isFieldValid) {
      const newField = { ...inputField };
      newField.fieldErrorAlert = event.target.name;
      newField[event.target.name] = "";
      setInputField(newField);
    }
  };

  return (
    <Box>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={1}
        sx={{ marginBottom: "20px" }}
      >
        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Product Code
              </Typography>
              {inputField.fieldErrorAlert == "pro_refer" && (
                <small style={{ color: "#CB0101" }}>
                  *Product Code field is empty
                </small>
              )}
            </Stack>

            <OutlinedInput
              ref={fieldRef}
              type="text"
              name="pro_refer"
              value={inputField?.pro_refer}
              size="small"
              placeholder="Enter pro_refer"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton size="small" onClick={handleRand}>
                    <Edit sx={{ fontSize: "11pt" }} />
                  </IconButton>
                </InputAdornment>
              }
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                paddingY: "0px",
                fontSize: "9pt",
                boxSizing: "border-box",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Item Description
              </Typography>
              {inputField.fieldErrorAlert === "title" && (
                <small style={{ color: "#CB0101" }}>
                  *Item description is empty
                </small>
              )}
            </Stack>

            <TextareaAutosize
              aria-label="minimum height"
              minRows={2}
              placeholder="Enter your address"
              // value={about}
              name="title"
              className="customTextField"
              style={{
                fontSize: "9pt",
                fontFamily: `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, "Oxygen",
                        Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
                        sans-serif`,
                width: "100%",
                maxWidth: "100%",
                backgroundColor: "#D4EAFF",
                boxSizing: "border-box",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "6px",
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "10px",
                // paddingBottom: "5px",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                Quantity
              </Typography>
              {inputField.fieldErrorAlert === "quantity" && (
                <small style={{ color: "#CB0101" }}>*Enter quantity</small>
              )}
            </Stack>

            <OutlinedInput
              ref={fieldRef}
              type="number"
              name="quantity"
              size="small"
              placeholder="Enter quantity"
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack direction={"row"} justifyContent="space-between">
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                price
              </Typography>
              {inputField.fieldErrorAlert === "price" && (
                <small style={{ color: "#CB0101" }}>*price empty</small>
              )}
            </Stack>

            <OutlinedInput
              ref={fieldRef}
              type="number"
              name="price"
              size="small"
              placeholder="price"
              endAdornment={<InputAdornment position="end">AED</InputAdornment>}
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",

                fontSize: "9pt",
              }}
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack spacing={0.5}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: "10pt",
                  fontWeight: 600,
                }}
              >
                itemTotal
              </Typography>
              {inputField.fieldErrorAlert === "itemTotal" && (
                <small style={{ color: "#CB0101" }}>*itemTotal empty</small>
              )}
            </Stack>

            <OutlinedInput
              ref={fieldRef}
              type="number"
              size="small"
              name="itemTotal"
              placeholder="itemTotal"
              endAdornment={<InputAdornment position="end">AED</InputAdornment>}
              sx={{
                width: "100%",
                bgcolor: "#D4EAFF",
                borderRadius: "6px",
                fontSize: "9pt",
              }}
              value={
                parseFloat(inputField.price) * parseFloat(inputField.quantity)
              }
              onChange={handleChange}
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent="right"
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ textTransform: "capitalize" }}
              onClick={() => {
                clear();
                setAddNewBtn(false);
              }}
            >
              Clear
            </Button>

            {inputField.price &&
            inputField.pro_refer &&
            inputField.quantity &&
            inputField.title ? (
              <Button
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  handleAdd();
                  setAddNewBtn(false);
                }}
              >
                Add
              </Button>
            ) : (
              <Button
                disabled
                variant="contained"
                color="success"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {}}
              >
                Add
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewOrderForm;
