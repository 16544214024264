import { Call, Language } from "@mui/icons-material";
import { Button, Container, Grid, Paper, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import React from "react";

const AppDownload = () => {
  return (
    <Container>
      <Paper
        sx={{
          padding: "10px",
          paddingTop: "35px",
          paddingBottom: "10px",
          height: { xs: "300px", md: "150px" },
          boxSizing: "border-box",
        }}
        elevation={0}
      >
        <Grid
          container
          rowSpacing={{ xs: 4, md: 2 }}
          spacing={0}
          sx={{ paddingLeft: "50px", boxSizing: "border-box" }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <Stack
                spacing={2}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  justifyContent="right"
                  alignItems={"center"}
                  sx={{
                    width: "15%",
                    height: "37px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    boxSizing: "border-box",
                  }}
                >
                  <Paper
                    sx={{
                      borderRadius: "50%",
                      width: "60px",
                      height: "60px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                      sx={{
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                        borderRadius: "50%",
                        boxSizing: "border-box",
                      }}
                    >
                      {/* <Box
            component="img"
            sx={{
              // padding: "0 12px",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
            }}
            alt="The house from the offer."
            //   src={logo}
          /> */}
                      <Language
                        sx={{
                          fontSize: "25pt",
                          color: "#3ecfcb",
                        }}
                      />
                    </Stack>
                  </Paper>
                </Stack>
                <Stack sx={{ boxSizing: "border-box" }}>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "12pt", fontWeight: 700 }}
                  >
                    Address
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ fontSize: "10pt", fontWeight: 500, color: "gray" }}
                  >
                    near Gold Souq Ajman-U.A.E
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box>
              <Stack
                spacing={2}
                direction={"row"}
                justifyContent="left"
                alignItems={"center"}
              >
                <Stack
                  direction={"row"}
                  justifyContent="right"
                  alignItems={"center"}
                  sx={{
                    boxSizing: "border-box",
                    width: "15%",
                    height: "37px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                  }}
                >
                  <Paper
                    sx={{
                      boxSizing: "border-box",
                      borderRadius: "50%",
                      width: "60px",
                      height: "60px",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      justifyContent="center"
                      alignItems={"center"}
                      sx={{
                        boxSizing: "border-box",
                        width: "100%",
                        height: "100%",
                        backgroundColor: "white",
                        borderRadius: "50%",
                      }}
                    >
                      <Call
                        sx={{
                          fontSize: "25pt",
                          color: "#3ecfcb",
                        }}
                      />
                    </Stack>
                  </Paper>
                </Stack>
                <Stack sx={{ boxSizing: "border-box" }}>
                  <Typography
                    variant="p"
                    sx={{
                      boxSizing: "border-box",
                      fontSize: "12pt",
                      fontWeight: 700,
                    }}
                  >
                    Whatsapp
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      boxSizing: "border-box",
                      fontSize: "15pt",
                      fontWeight: 500,
                      color: "#3ecfcb",
                    }}
                  >
                    +971558332996
                  </Typography>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box sx={{ boxSizing: "border-box" }}>
              <Stack
                // direction={"row"}
                justifyContent="space-between"
                alignItems={{ xs: "center", md: "flex-start" }}
                sx={{
                  marginTop: { xs: "0px", md: "" },
                  marginLeft: { xs: "-120px", md: "0" },
                  boxSizing: "border-box",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    boxSizing: "border-box",
                    borderRadius: "30px",
                    bgcolor: "#19a7d2",
                    marginLeft: { xs: "-155px", md: "50px" },
                  }}
                >
                  Work with us
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default AppDownload;
