import {
  Facebook,
  Instagram,
  LinkedIn,
  LocationOn,
  Mail,
  PhoneIphone,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useState } from "react";
import concactUs from "../images/contactUs.webp";

const Contact = () => {
  const [userType, setUserType] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handlePhoneChange = (event) => {
    setPhone(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  return (
    <Container>
      <Stack mt={4}>
        <Typography
          variant="p"
          sx={{ fontSize: "20pt", fontWeight: 600, textAlign: "center" }}
        >
          Send Us Following Information
        </Typography>
        <Box sx={{ paddingY: "5%", paddingX: "10%" }}>
          <Grid container spacing={2} sx={{ bgcolor: "" }}>
            <Grid item xs={12} md={6}>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"center"}
                sx={{ height: { md: "450px", xs: "auto" } }}
              >
                <Box
                  component={"img"}
                  src={concactUs}
                  sx={{ width: "80%", marginLeft: "-60px" }}
                />
                <Stack spacing={1.5}>
                  <Typography
                    variant="p"
                    sx={{ color: "white", fontSize: "14pt", fontWeight: 600 }}
                  >
                    Contact us
                  </Typography>
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <LocationOn
                      sx={{
                        color: "black",
                        fontSize: "11pt",
                        cursor: "pointer",
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: "black",
                        fontSize: "11pt",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    >
                      Head Office:
                    </Typography>
                  </Stack>
                  <Typography
                    variant="p"
                    sx={{
                      color: "black",
                      fontSize: "11pt",
                      fontWeight: 500,
                      cursor: "pointer",
                    }}
                  >
                    Shop No 2, FLoor G, Plot 0076 City Center, Sector Nakheel 1
                    Ajman UAE
                  </Typography>
                  <Stack
                    spacing={0.5}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <PhoneIphone
                      sx={{
                        color: "black",
                        fontSize: "11pt",
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: "black",
                        fontSize: "11pt",
                        fontWeight: 500,
                      }}
                    >
                      Phone:
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        color: "black",
                        fontSize: "11pt",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    >
                      +971558332996
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Mail
                      sx={{
                        color: "black",
                        fontSize: "11pt",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    />
                    <Typography
                      variant="p"
                      sx={{
                        color: "black",
                        fontSize: "11pt",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    >
                      info@alqamaralzhabi.com
                    </Typography>
                  </Stack>
                  <Stack
                    spacing={1}
                    direction={"row"}
                    justifyContent="left"
                    alignItems={"center"}
                  >
                    <Facebook
                      sx={{
                        color: "black",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    />
                    <YouTube
                      sx={{
                        color: "black",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    />
                    <Instagram
                      sx={{
                        color: "black",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    />
                    <Twitter
                      sx={{
                        color: "black",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    />
                    <LinkedIn
                      sx={{
                        color: "black",
                        fontSize: "13pt",
                        fontWeight: 500,
                        cursor: "pointer",
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack
                spacing={2}
                sx={{ paddingY: "10%", paddingX: "20%", bgcolor: "" }}
              >
                <Stack spacing={0.5}>
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    Full Name
                  </Typography>

                  <OutlinedInput
                    //   defaultValue="react-bootstrap"
                    value={name}
                    placeholder="Please enter your name"
                    type="text"
                    size="small"
                    id="bootstrap-input"
                    onChange={handleNameChange}
                    sx={{ borderRadius: "4px" }}
                  />
                </Stack>
                <Stack>
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    Email
                  </Typography>

                  <OutlinedInput
                    //   defaultValue="react-bootstrap"
                    value={email}
                    placeholder="Please enter phone no"
                    type="email"
                    size="small"
                    id="bootstrap-input"
                    sx={{ borderRadius: "4px" }}
                    onChange={handleEmailChange}
                  />
                </Stack>

                <Stack>
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    Phone
                  </Typography>

                  <OutlinedInput
                    value={phone}
                    placeholder="Please enter text"
                    type="number"
                    size="small"
                    sx={{ borderRadius: "4px" }}
                    onChange={handlePhoneChange}
                  />
                </Stack>
                <Stack>
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    User Type
                  </Typography>

                  <Select
                    size="small"
                    value={userType}
                    onChange={handleUserTypeChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Please Select One</MenuItem>
                    <MenuItem value={"Individual"}>Individual</MenuItem>
                    <MenuItem value={"Company"}>Company</MenuItem>
                  </Select>
                </Stack>
                <Stack>
                  <Typography variant="p" sx={{ fontSize: "12pt" }}>
                    Message
                  </Typography>

                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Minimum 3 rows"
                    style={{ width: "98%" }}
                  />
                </Stack>
                <Stack
                  direction={"row"}
                  justifyContent="space-between"
                  alignItems={"center"}
                ></Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Container>
  );
};

export default Contact;
