import { Box, Container } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Pages from "./Pages/Pages";
import Header from "./components/Header";
import MenuTags from "./components/MenuTags";
import { createContext, useState } from "react";
import Login from "./Pages/AdminLogin";
import FooterBottom from "./components/HomePageComponents/Footer/FooterBottom";
import Footer from "./components/HomePageComponents/Footer/Footer";

export const UserContext = createContext();
export const ButtonContext = createContext();
export const DPproductContext = createContext();
function App() {
  const [cart, setCart] = useState([]);
  const [loggedInUser, setLoggedInUser] = useState({});
  const [DPproduct, setDPproduct] = useState({});
  const [upLeftBtn, setUpLeftBtn] = useState("");

  return (
    <ButtonContext.Provider value={[upLeftBtn, setUpLeftBtn]}>
      <DPproductContext.Provider value={[DPproduct, setDPproduct]}>
        <UserContext.Provider value={[loggedInUser, setLoggedInUser]}>
          <BrowserRouter>
            <Header />
            <MenuTags />
            <Pages />
            {/* <Routes>
            <Route path="/login" element={<Login />} />
          </Routes> */}
            <Footer />
            <FooterBottom />
          </BrowserRouter>
        </UserContext.Provider>
      </DPproductContext.Provider>
    </ButtonContext.Provider>
  );
}

export default App;
