import { Card, Grid, Paper, Typography } from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import aboutImg1 from "../images/about_img1.png";
import CompanyProfile from "../components/AboutPage Components/CompanyProfile";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const About = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      {/* <Stack> */}
      <Grid container spacing={2} sx={{ bgcolor: "" }}>
        <Grid item xs={12} md={6} sx={{ position: "relative" }}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"center"}
            sx={{ height: "550px" }}
          >
            <Box
              component={"img"}
              src={aboutImg1}
              sx={{ height: "450px", width: "auto" }}
            />
          </Stack>
          <Paper
            elevation={0}
            sx={{
              padding: "15px",
              width: "60%",
              position: "absolute",
              bottom: "75px",
              right: "57px",
            }}
          >
            <Stack>
              <Typography
                variant="p"
                sx={{
                  textAlign: "center",
                  fontSize: "15pt",
                  fontWeight: 700,
                }}
              >
                Successfully Providing Business <br />
                Solution from 11 years
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            alignItems="flex-start"
            justifyContent={"center"}
            sx={{ height: "550px", boxSizing: "border-box" }}
            spacing={0}
          >
            <Typography
              variant="p"
              sx={{
                fontSize: "25pt",
                fontWeight: 700,
                height: "150px",
                paddingTop: { xs: "0px", md: "100px" },
              }}
            >
              Thank you for your interest in <br /> Al QAMAR AL ZHABI
            </Typography>
            <Typography variant="p" sx={{ height: "150px" }}>
              Al Qamar Al Zhabi is a tech product selling and servicing company
              based in Azman, United Arab Emirates. We offer a wide range of
              products such as laptops, PCs, smartphones, peripherals, gaming
              consoles, and other consumer electronics. Additionally, they
              specialize in providing post-sales services such as repairs and
              maintenance.
            </Typography>
            <Box sx={{ width: "100%", height: "350px" }}>
              <Box sx={{ borderBottom: 1, borderColor: "gray" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Our Vission"
                    {...a11yProps(0)}
                    sx={{ margin: "auto" }}
                  />
                  <Tab
                    label="Our Mission"
                    {...a11yProps(1)}
                    sx={{ margin: "auto" }}
                  />
                  <Tab
                    label="Our Value"
                    {...a11yProps(2)}
                    sx={{ margin: "auto" }}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <Typography variant="p" sx={{ fontSize: "12pt" }}>
                  To be the leading provider of innovative technology products,
                  offering our customers the highest quality products and
                  services at competitive prices.
                </Typography>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Typography variant="p" sx={{ fontSize: "12pt" }}>
                  To provide our customers with the best possible experience by
                  delivering innovative technology products and services that
                  are tailored to their needs. We strive to provide a positive
                  customer experience and ensure that our products and services
                  exceed customer expectations.
                </Typography>
              </TabPanel>
              <TabPanel value={value} index={2}>
                <Typography variant="p" sx={{ fontSize: "12pt" }}>
                  We value customer satisfaction, integrity, innovation, and
                  excellence in all our products and services. We strive to
                  build relationships with our customers and provide them with
                  the best possible experience. We are committed to providing a
                  safe and secure environment for our customers and employees.
                </Typography>
              </TabPanel>
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <CompanyProfile />
      {/* </Stack> */}
    </Container>
  );
};

export default About;
