import { HighlightOff, ShoppingBag } from "@mui/icons-material";
import { Button, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import ShoppingCartItem from "./ShoppingCartItem";
import { useCart } from "react-use-cart";
import Empty from "./Empty";

const CartLeft = () => {
  const navigate = useNavigate();
  const {
    isEmpty,
    items,
    cartTotal,
    updateItemQuantity,
    removeItem,
    emptyCart,
  } = useCart();

  const clearCartFromLocal = () => {
    emptyCart();
    navigate("/");
  };

  return (
    <Stack
      spacing={1}
      sx={{ width: { xs: "100%", sm: "67%" }, padding: { xs: 0, sm: "15px" } }}
    >
      <Typography variant="caption">
        {isEmpty ? <Empty /> : "The Cart"}
      </Typography>
      {items.map((element) => (
        <ShoppingCartItem data={element} />
      ))}

      {!isEmpty && <Divider />}
      {!isEmpty && (
        <Stack
          direction={{ sm: "column", md: "row" }}
          spacing={{ xs: 2, sm: 2 }}
          justifyContent={{ xs: "center", sm: "space-between" }}
          alignItems={{ xs: "center", sm: "space-between" }}
        >
          <Button
            variant="contained"
            startIcon={<ShoppingBag />}
            sx={{ bgcolor: "black", width: { sm: "62%", md: "32%" } }}
          >
            CONTINUE SHOPPING
          </Button>
          <Stack spacing={1} direction="row">
            <Button
              variant="contained"
              startIcon={<HighlightOff />}
              onClick={clearCartFromLocal}
              sx={{
                bgcolor: "black",
                color: "white",
                "&:hover": {
                  color: "white",
                },
              }}
            >
              <Typography variant="caption">Clear Cart</Typography>
            </Button>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default CartLeft;
