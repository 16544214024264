import { Box } from "@mui/material";
import React from "react";

import OrderProcess from "../components/HomePageComponents/OrderProcess/OrderProcess";

import BannerSlider from "../components/HomePageComponents/Banner/bannerSlider/BannerSlider";
import AppDownload from "../components/HomePageComponents/App Download/AppDownload";
import Statistics from "../components/HomePageComponents/Statistics/Statistics";
import CategoryGridLaptop from "../components/HomePageComponents/ProductCategoryGrids/CategoryGridLaptop";
import CategoryGridMobile from "../components/HomePageComponents/ProductCategoryGrids/CategoryGridMobile";
import CategoryGridDesktop from "../components/HomePageComponents/ProductCategoryGrids/CategoryGridDesktop";
import CategoryGridRouter from "../components/HomePageComponents/ProductCategoryGrids/CategoryGridRouter";
import CategoryGridAccessPoint from "../components/HomePageComponents/ProductCategoryGrids/CategoryGridAccessPoint";
import CategoryGridSwitch from "../components/HomePageComponents/ProductCategoryGrids/CategoryGridSwitch";

const Home = () => {
  return (
    <Box>
      <BannerSlider />
      <Box sx={{ paddingTop: { xs: "60px", md: "40px" }, bgcolor: "#F2F3F5" }}>
        <CategoryGridMobile />
      </Box>
      <Box sx={{ paddingTop: "40px", bgcolor: "#F2F3F5" }}>
        <CategoryGridLaptop />
      </Box>
      <Box sx={{ paddingTop: "40px", bgcolor: "#F2F3F5" }}>
        <CategoryGridDesktop />
      </Box>
      <Box sx={{ paddingTop: "40px", bgcolor: "#F2F3F5" }}>
        <CategoryGridRouter />
      </Box>
      <Box sx={{ paddingTop: "40px", bgcolor: "#F2F3F5" }}>
        <CategoryGridAccessPoint />
      </Box>
      <Box
        sx={{ paddingTop: "40px", bgcolor: "#F2F3F5", paddingBottom: "40px" }}
      >
        <CategoryGridSwitch />
      </Box>

      <OrderProcess />
      <Statistics />

      <AppDownload />
    </Box>
  );
};

export default Home;
