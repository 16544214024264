import {
  Alert,
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./PaymentMethod.css";
import cashOnPic from "../../../images/Cash on.png";
import Bkash from "../../../images/bkash.png";
import Nagad from "../../../images/nagad.png";
import paypal from "../../../images/paypal.png";
import ghorami from "../../../images/ghorami.ico";
import nexus from "../../../images/card/download.jpg";
import dbdebit from "../../../images/card/butch.jpg";
import master from "../../../images/card/mastercard-logo-4EB70F23D3-seeklogo.com.png";
import visa from "../../../images/card/VISA_Logo1.svg_.png";

const wallet = [
  {
    name: "bkash",
    charge: 1.5,
    pic: Bkash,
    id: 2,
  },
  {
    name: "nagad",
    charge: 1.2,
    pic: Nagad,
    id: 1,
  },
];
const card = [
  { name: "mastercard", pic: master, id: "4", charge: 0 },
  { name: "visa", pic: visa, id: "5", charge: 0 },
  { name: "dbdebit", pic: dbdebit, id: "6", charge: 0 },
  { name: "nexus", pic: nexus, id: "7", charge: 0 },
];
const international = [
  { name: "paypal", pic: paypal, id: "9", charge: 0 },
  { name: "ghorami", pic: ghorami, id: "8", charge: 0 },
];

const PaymentMethod = (props) => {
  const navigate = useNavigate();
  const {
    handleGatewayCharge,
    activeTab,
    handleActiveTab,
    setPaymentType,
    paymentType,
  } = props;

  const [alert, setAlert] = useState(0);

  // const handleOpen = () => {
  //   setOpen(true);
  //   if (!activeTab) {
  //     setAlert(1);
  //   } else {
  //     setAlert(0);
  //     // navigate("/nagad");
  //   }
  // };
  // const handleClose = () => setOpen(false);

  return (
    <Box sx={{ marginTop: "20px" }}>
      <Typography variant="p" sx={{ fontSize: "14pt", fontWeight: 700 }}>
        Payment Method
      </Typography>
      <Paper sx={{ marginTop: "20px" }} elevation={5}>
        {alert === 1 && (
          <Alert
            severity="warning"
            onClose={() => {
              setAlert(0);
            }}
            sx={{ marginBottom: "5px", marginTop: "-6%" }}
          >
            Must choose a payment method
          </Alert>
        )}
        <Grid
          container
          rowSpacing={0}
          columnSpacing={0}
          sx={{ paddingTop: "0px" }}
        >
          <Grid item xs={4}>
            <Stack spacing={1} justifyContent="center" alignItems="flex-start">
              <Stack sx={{ paddingTop: "15px", width: "100%" }}>
                <Stack
                  spacing={0.5}
                  justifyContent="center"
                  alignItems={"center"}
                  className="listContainer"
                >
                  {" "}
                  <Button
                    sx={{
                      width: "100%",
                      fontSize: "11pt",
                      fontWeight: 600,
                      color: "black",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#E0E8BF",
                      },
                    }}
                    size="small"
                    className={paymentType === "cash" ? "selected" : ""}
                    onClick={() => {
                      setPaymentType("cash");
                    }}
                  >
                    Cash on
                  </Button>
                  {/* <Button
                    sx={{
                      width: "100%",
                      fontSize: "11pt",
                      fontWeight: 600,
                      color: "black",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#E0E8BF",
                      },
                    }}
                    className={paymentType === "wallet" ? "selected" : ""}
                    size="small"
                    onClick={() => {
                      setPaymentType("wallet");
                    }}
                  >
                    Mobile Wallet
                  </Button> */}
                  <Button
                    sx={{
                      width: "100%",
                      fontSize: "11pt",
                      fontWeight: 600,
                      color: "black",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#E0E8BF",
                      },
                    }}
                    className={paymentType === "card" ? "selected" : ""}
                    size="small"
                    onClick={() => {
                      setPaymentType("card");
                    }}
                  >
                    Card
                  </Button>
                  <Button
                    sx={{
                      width: "100%",
                      fontSize: "11pt",
                      fontWeight: 600,
                      color: "black",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#E0E8BF",
                      },
                    }}
                    className={
                      paymentType === "international" ? "selected" : ""
                    }
                    size="small"
                    onClick={() => {
                      setPaymentType("international");
                    }}
                  >
                    International
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={8}>
            <Box>
              {/* {paymentType === "wallet" && (
                <Paper
                  sx={{
                    backgroundColor: "#E0E8BF",
                    height: "20%",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      width: "100%",
                      height: "25vh",
                      backgroundColor: "#E0E8BF",
                      "&:hover": {},
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {wallet?.map((element) => (
                      <Box
                        sx={{
                          width: "30%",
                          borderRadius: "6px",
                          transition: "all .5s",
                          "&:hover": {
                            transform: "TranslateY(-5px)",
                          },
                        }}
                        className={
                          activeTab === element.name
                            ? "selectedPaymentGateway"
                            : ""
                        }
                      >
                        <Box
                          component="img"
                          sx={{
                            width: "100%",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleActiveTab(element.name);
                            handleGatewayCharge(element.charge, element);
                          }}
                          alt="The house from the offer."
                          src={element.pic}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Paper>
              )} */}
              {paymentType === "card" && (
                <Paper
                  sx={{
                    backgroundColor: "#E0E8BF",
                    height: "20%",
                  }}
                >
                  <Box
                    // direction="row"
                    // spacing={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      width: "100%",
                      height: "25vh",
                      backgroundColor: "#E0E8BF",
                      "&:hover": {},
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {card?.map((element) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "40%",
                          height: "75px",
                          borderRadius: "5px",
                          transition: "all .5s",
                          "&:hover": {
                            transform: "TranslateY(-5px)",
                          },
                        }}
                        className={
                          activeTab === element.name
                            ? "selectedPaymentGateway"
                            : ""
                        }
                      >
                        <Box
                          component="img"
                          sx={{
                            width: "100px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleActiveTab(element.name);
                            handleGatewayCharge(element.charge, element);
                          }}
                          alt="The house from the offer."
                          src={element.pic}
                        />
                      </Box>
                    ))}
                  </Box>
                </Paper>
              )}
              {paymentType === "international" && (
                <Paper
                  sx={{
                    backgroundColor: "#E0E8BF",
                    // height: "20%",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      width: "100%",
                      height: "25vh",
                      backgroundColor: "#E0E8BF",
                      "&:hover": {},
                    }}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {international?.map((element) => (
                      <Box
                        sx={{
                          width: "30%",
                          borderRadius: "6px",
                          transition: "all .5s",
                          "&:hover": {
                            transform: "TranslateY(-5px)",
                          },
                        }}
                        className={
                          activeTab === element.name
                            ? "selectedPaymentGateway"
                            : ""
                        }
                      >
                        <Box
                          component="img"
                          sx={{
                            width: "100%",
                            transform:
                              element.name == "paypal"
                                ? "scale(1.5)"
                                : "scale(.9)",
                            cursor: "pointer",
                            // borderRadius: "50%",
                          }}
                          onClick={() => {
                            handleActiveTab(element.name);
                            handleGatewayCharge(element.charge, element);
                          }}
                          alt="The house from the offer."
                          src={element.pic}
                        />
                      </Box>
                    ))}
                  </Stack>
                </Paper>
              )}

              {paymentType === "cash" && (
                <Stack
                  direction="row"
                  spacing={0}
                  sx={{
                    width: { xs: "100%", md: "100%" },
                    height: "25vh",
                    backgroundColor: "#E0E8BF",
                  }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box
                    sx={{
                      width: "30%",
                      transition: "all .5s",
                      "&:hover": {
                        transform: "TranslateY(-5px)",
                      },
                    }}
                    className={
                      activeTab === "cashOn" ? "selectedPaymentGateway" : ""
                    }
                  >
                    <Box
                      component="img"
                      sx={{
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleActiveTab("cashOn");
                        // handleGatewayCharge(element.charge, element);
                      }}
                      alt="The house from the offer."
                      src={cashOnPic}
                    />
                  </Box>
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default PaymentMethod;
